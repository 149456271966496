import React from "react";
import { Link } from "react-router-dom";


const TermsandConditions = () => {
  return (
    <>
      <div className="container p-5">
      <Link to="/">Back</Link>

        <h4 className="title">TERMS OF SERVICE</h4>
        <div className="terms-section">
          <div>
            <section>
              <h5>I. Acceptance of terms</h5>
              <p>
                Thank you for using BHAGVA. These Terms of Service (the "Terms")
                are intended to make you aware of your legal rights and
                responsibilities with respect to your access to and use of the
                BHAGVA website at <a href="www.bhagva.in">www.bhagva.in</a> (the
                "Site") and any related mobile or software applications ("BHAGVA
                Platform") including but not limited to delivery of information
                via the website whether existing now or in the future that link
                to the Terms (collectively, the "Services").
              </p>
              <p>
                These Terms are effective for all existing and future BHAGVA
                customers, including but without limitation to users having
                access to the 'Business Page' to manage their claimed business
                listings.
              </p>
              <p>
                Please read these Terms carefully. By accessing or using the
                BHAGVA Platform, you are agreeing to these Terms and concluding
                a legally binding contract with BHAGVA-ONTECH PRIVATE LIMITED
                and/or its affiliates (hereinafter collectively referred to as
                "BHAGVA"). You may not use the Services if you do not accept the
                Terms or are unable to be bound by the Terms. Your use of the
                BHAGVA Platform is at your own risk, including the risk that you
                might be exposed to content that is objectionable, or otherwise
                inappropriate.
              </p>
              <p>
                In order to use the Services, you must first agree to the Terms.
                You can accept the Terms by:
              </p>
              <ul>
                <li>
                  Clicking to accept or agree to the Terms, where it is made
                  available to you by BHAGVA in the user interface for any
                  particular Service; or
                </li>
                <li>
                  Actually, using the Services. In this case, you understand and
                  agree that BHAGVA will treat your use of the Services as
                  acceptance of the Terms from that point onwards.
                </li>
              </ul>
            </section>
            <section>
              <h5>II. Definitions</h5>
              <h6>Customer</h6>
              <p>
                "Customer" or "You" or "Your" refers to you, as a customer of
                the Services. A customer is someone who accesses or uses the
                Services for the purpose of sharing, displaying, hosting,
                publishing, transacting, or uploading information or views or
                pictures and includes other persons jointly participating in
                using the Services including without limitation a user having
                access to 'Business Page' to manage claimed business listings or
                otherwise.
              </p>
              <h6>Content</h6>
              <p>
                "Content" will include (but is not limited to) reviews, images,
                photos, audio, video, location data, nearby places, and all
                other forms of information or data. "Your content" or "Customer
                Content" means content that you upload, share or transmit to,
                through or in connection with the Services, such as likes,
                ratings, reviews, images, photos, messages, chat communication,
                profile information, or any other materials that you publicly
                display or displayed in your account profile. "BHAGVA Content"
                means content that BHAGVA creates and make available in
                connection with the Services including, but not limited to,
                visual interfaces, interactive features, graphics, design,
                compilation, computer code, products, software, aggregate
                ratings, reports and other usage-related data in connection with
                activities associated with your account and all other elements
                and components of the Services excluding Your Content and Third
                Party Content. "Third Party Content" means content that comes
                from parties other than BHAGVA or its Customers and is available
                on the Services.
              </p>
            </section>
            <section>
              <h5>III. Eligibility to use the services</h5>
              <p>
                1. You hereby represent and warrant that you are at least
                eighteen (18) years of age or above and are fully able and
                competent to understand and agree the terms, conditions,
                obligations, affirmations, representations, and warranties set
                forth in these Terms.
              </p>
              <p>
                2. Compliance with Laws. You are in compliance with all laws and
                regulations in the country in which you live when you access and
                use the Services. You agree to use the Services only in
                compliance with these Terms and applicable law, and in a manner
                that does not violate our legal rights or those of any third
                party(ies).
              </p>
            </section>
            <section>
              <h5>IV. Changes to the terms</h5>
              <p>
                BHAGVA may vary or amend or change or update these Terms, from
                time to time entirely at its own discretion. You shall be
                responsible for checking these Terms from time to time and
                ensure continued compliance with these Terms. Your use of BHAGVA
                Platform after any such amendment or change in the Terms shall
                be deemed as your express acceptance to such amended/changed
                terms and you also agree to be bound by such changed/amended
                Terms.
              </p>
            </section>
            <section>
              <h5>V. Translation of the terms</h5>
              <p>
                BHAGVA may provide a translation of the English version of the
                Terms into other languages. You understand and agree that any
                translation of the Terms into other languages is only for your
                convenience and that the English version shall govern the terms
                of your relationship with BHAGVA. Furthermore, if there are any
                inconsistencies between the English version of the Terms and its
                translated version, the English version of the Terms shall
                prevail over others.
              </p>
            </section>
            <section>
              <h5>VI. Provision of the services being offered by BHAGVA</h5>
              <p>
                1. BHAGVA is constantly evolving in order to provide the best
                possible experience and information to its Customers. You
                acknowledge and agree that the form and nature of the Services
                which BHAGVA provides, may require affecting certain changes in
                it, therefore, BHAGVA reserves the right to suspend/cancel, or
                discontinue any or all products or services at any time without
                notice, make modifications and alterations in any or all of its
                contents, products and services contained on the site without
                any prior notice.
              </p>
              <p>
                2. We, the software, or the software application store that
                makes the software available for download may include
                functionality to automatically check for updates or upgrades to
                the software. Unless your device, its settings, or computer
                software does not permit transmission or use of upgrades or
                updates, you agree that we, or the applicable software or
                software application store, may provide notice to you of the
                availability of such upgrades or updates and automatically push
                such upgrade or update to your device or computer from
                time-to-time. You may be required to install certain upgrades or
                updates to the software in order to continue to access or use
                the Services, or portions thereof (including upgrades or updates
                designed to correct issues with the Services). Any updates or
                upgrades provided to you by us under the Terms shall be
                considered part of the Services.
              </p>
              <p>
                3. You acknowledge and agree that if BHAGVA disables access to
                your account, you may be prevented from accessing the Services,
                your account details or any files or other content, which is
                contained in your account.
              </p>
              <p>
                4. You acknowledge and agree that while BHAGVA may not currently
                have set a fixed upper limit on the number of transmissions you
                may send or receive through the Services, BHAGVA may set such
                fixed upper limits at any time, at BHAGVA's discretion.
              </p>
              <p>
                5. In our effort to continuously improve the BHAGVA Platform and
                Services, we undertake research and conduct experiments from
                time to time on various aspects of the Services and offerings,
                including our apps, websites, user interface and promotional
                campaigns. As a result of which, some Customers may experience
                features differently than others at any given time. This is for
                making the BHAGVA Platform better, more convenient and easy to
                use, improving Customer experience, enhancing the safety and
                security of our services and offerings and developing new
                services and features.
              </p>
              <p>
                6. By using BHAGVA's Services you agree to the following
                disclaimers:
              </p>
              <ul>
                <li>
                  The Content on these Services is for informational purposes
                  only. BHAGVA disclaims any liability for any information that
                  may have become outdated since the last time the particular
                  piece of information was updated. BHAGVA reserves the right to
                  make changes and corrections to any part of the Content on
                  these Services at any time without prior notice. BHAGVA does
                  not guarantee the quality of the Goods, the prices listed in
                  menus or the availability of all menu items at any Merchant.
                  Unless stated otherwise, all pictures and information
                  contained on these Services are believed to be owned by or
                  licensed to BHAGVA. Please email a takedown request (by using
                  the "Contact Us" link on the home page) to the webmaster if
                  you are the copyright owner of any Content on these Services
                  and you think the use of the above material violates Your
                  copyright in any way. Please indicate the exact URL of the
                  webpage in your request. All images shown here have been
                  digitized by BHAGVA. No other party is authorized to reproduce
                  or republish these digital versions in any format whatsoever
                  without the prior written permission of BHAGVA.
                </li>
                <li>
                  Any certification, licenses or permits ("Certification") or
                  information in regard to such Certification that may be
                  displayed on the Merchant's listing page on the BHAGVA
                  Platform is for informational purposes only. Such
                  Certification is displayed by BHAGVA on an 'as available'
                  basis that is provided to BHAGVA by the Merchant partner(s).
                  BHAGVA does not make any warranties about the validity,
                  authenticity, reliability and accuracy of such Certification
                  or any information displayed in this regard. Any reliance by a
                  Customer upon the Certification or information thereto shall
                  be strictly at such Customer's own risk and BHAGVA in no
                  manner shall assume any liability whatsoever for any losses or
                  damages in connection with the use of this information or for
                  any inaccuracy, invalidity or discrepancy in the Certification
                  or non-compliance of any applicable local laws or regulations
                  by the Merchant partner/Merchant.
                </li>
              </ul>
              <p>
                7. BHAGVA reserves the right to charge a subscription and/or
                membership and/or a convenience fee from a Customer, by giving
                reasonable prior notice, in respect of any product, service or
                any other aspect of the BHAGVA Platform anytime in future.
              </p>
              <p>
                8. BHAGVA may from time to time introduce referral and/or
                incentive based programs for its Customers (Program). These
                Program(s) may be governed by their respective terms and
                conditions. By participating in the Program, Customers are bound
                by the Program terms and conditions as well as the BHAGVA
                Platform terms. Further, BHAGVA reserves the right to terminate
                / suspend the Customer's account and/or credits / points earned
                and/or participation of the Customer in the Program if BHAGVA
                determines in its sole discretion that the Customer has violated
                the rules of the Program and/or has been involved in activities
                that are in contravention of the Program terms and/or BHAGVA
                Platform terms or has engaged in activities which are fraudulent
                / unlawful in nature. Furthermore, BHAGVA reserves the right to
                modify, cancel and discontinue its Program without notice to the
                Customer.
              </p>
            </section>
            <section>
              <h5>VII. Use of services by you or Customer</h5>
              <h6>
                BHAGVA Customer Account Including 'Claim Your Business Listing'
                Access
              </h6>
              <p>
                a. You must create an account in order to use some of the
                features offered by the Services, including without limitation
                to 'claim your business listing' on the Services. Use of any
                personal information you provide to us during the account
                creation process is governed by our Privacy Policy. You must
                keep your password confidential and you are solely responsible
                for maintaining the confidentiality and security of your
                account, all changes and updates submitted through your account,
                and all activities that occur in connection with your account.
              </p>
              <p>
                b. You may also be able to register to use the Services by
                logging into your account with your credentials from certain
                third-party social networking sites (e.g., Facebook). You
                confirm that you are the owner of any such social media account
                and that you are entitled to disclose your social media login
                information to us. You authorize us to collect your
                authentication information, and other information that may be
                available on or through your social media account consistent
                with your applicable settings and instructions.
              </p>
              <p>
                c. In creating an account and/or claiming your business'
                listing, you represent to us that all information provided to us
                in such process is true, accurate and correct, and that you will
                update your information as and when necessary in order to keep
                it accurate. If you are creating an account or claiming a
                business listing, then you represent to us that you are the
                owner or authorized agent of such business. You may not
                impersonate someone else, create or use an account for anyone
                other than yourself, provide an email address other than your
                own, create multiple accounts or business listings except as
                otherwise authorized by us, or provide or use false information
                to obtain access to a business' listing on the Services that you
                are not legally entitled to claim. You acknowledge that any
                false claiming of a business listing may cause BHAGVA or third
                parties to incur substantial economic damages and losses for
                which you may be held liable and accountable.
              </p>
              <p>
                d. You are also responsible for all activities that occur in
                your account. You agree to notify us immediately of any
                unauthorized use of your account in order to enable us to take
                necessary corrective action. You also agree that you will not
                allow any third party to use your BHAGVA account for any purpose
                and that you will be liable for such unauthorized access.
              </p>
              <p>
                e. By creating an account, you agree to receive certain
                communications in connection with BHAGVA Platform or Services.
                For example, you might receive comments from other Customers or
                other Customers may follow the activity to do on your account.
                You can opt-out or manage your preferences regarding
                non-essential communications through account settings.
              </p>
              <h6>Others Terms</h6>
              <p>
                a. In order to connect you to certain Merchants, we provide
                value added telephony services through our phone lines, which
                are displayed on the specific Merchant listing page on the
                BHAGVA Platform, which connect directly to Merchants' phone
                lines. We record all information regarding this call including
                the voice recording of the conversation between you, and the
                Merchant (for internal billing tracking purposes and customer
                service improvement at the Merchant's end). If you do not wish
                that your information be recorded in such a manner, please do
                not use the telephone services provided by BHAGVA. You
                explicitly agree and permit BHAGVA to record all this
                information when you avail the telephony services through the
                BHAGVA provided phone lines on the BHAGVA Platform.
              </p>
              <p>
                b. You agree to use the Services only for purposes that are
                permitted by (a) the Terms and (b) any applicable law,
                regulation or generally accepted practices or guidelines in the
                relevant jurisdictions.
              </p>
              <p>
                c. You agree to use the data owned by BHAGVA (as available on
                the Services or through any other means like API etc.) only for
                personal use/purposes and not for any commercial use (other than
                in accordance with 'Claim Your Business Listing' access) unless
                agreed to by/with BHAGVA in writing.
              </p>
              <p>
                d. You agree not to access (or attempt to access) any of the
                Services by any means other than the interface that is provided
                by BHAGVA, unless you have been specifically allowed to do so,
                by way of a separate agreement with BHAGVA. You specifically
                agree not to access (or attempt to access) any of the Services
                through any automated means (including use of scripts or web
                crawlers) and shall ensure that you comply with the instructions
                set out in any robots.txt file present on the Services.
              </p>
              <p>
                e. You agree that you will not engage in any activity that
                interferes with or disrupts the Services (or the servers and
                networks which are connected to the Services). You shall not
                delete or revise any material or information posted by any other
                Customer(s), shall not engage in spamming, including but not
                limited to any form of emailing, posting or messaging that is
                unsolicited.
              </p>
            </section>
            <section>
              <h5>VII. Use of services by you or Customer</h5>
              <h6>
                1. BHAGVA Customer Account Including 'Claim Your Business
                Listing' Access
              </h6>
              <p>
                a. You must create an account in order to use some of the
                features offered by the Services, including without limitation
                to 'claim your business listing' on the Services. Use of any
                personal information you provide to us during the account
                creation process is governed by our Privacy Policy. You must
                keep your password confidential and you are solely responsible
                for maintaining the confidentiality and security of your
                account, all changes and updates submitted through your account,
                and all activities that occur in connection with your account.
              </p>
              <p>
                b. You may also be able to register to use the Services by
                logging into your account with your credentials from certain
                third-party social networking sites (e.g., Facebook). You
                confirm that you are the owner of any such social media account
                and that you are entitled to disclose your social media login
                information to us. You authorize us to collect your
                authentication information, and other information that may be
                available on or through your social media account consistent
                with your applicable settings and instructions.
              </p>
              <p>
                c. In creating an account and/or claiming your business'
                listing, you represent to us that all information provided to us
                in such process is true, accurate and correct, and that you will
                update your information as and when necessary in order to keep
                it accurate. If you are creating an account or claiming a
                business listing, then you represent to us that you are the
                owner or authorized agent of such business. You may not
                impersonate someone else, create or use an account for anyone
                other than yourself, provide an email address other than your
                own, create multiple accounts or business listings except as
                otherwise authorized by us, or provide or use false information
                to obtain access to a business' listing on the Services that you
                are not legally entitled to claim. You acknowledge that any
                false claiming of a business listing may cause BHAGVA or third
                parties to incur substantial economic damages and losses for
                which you may be held liable and accountable.
              </p>
              <p>
                d. You are also responsible for all activities that occur in
                your account. You agree to notify us immediately of any
                unauthorized use of your account in order to enable us to take
                necessary corrective action. You also agree that you will not
                allow any third party to use your BHAGVA account for any purpose
                and that you will be liable for such unauthorized access.
              </p>
              <p>
                e. By creating an account, you agree to receive certain
                communications in connection with BHAGVA Platform or Services.
                For example, you might receive comments from other Customers or
                other Customers may follow the activity to do on your account.
                You can opt-out or manage your preferences regarding
                non-essential communications through account settings.
              </p>
              <h6>2. Others Terms</h6>
              <p>
                a. In order to connect you to certain Merchants, we provide
                value added telephony services through our phone lines, which
                are displayed on the specific Merchant listing page on the
                BHAGVA Platform, which connect directly to Merchants' phone
                lines. We record all information regarding this call including
                the voice recording of the conversation between you, and the
                Merchant (for internal billing tracking purposes and customer
                service improvement at the Merchant's end). If you do not wish
                that your information be recorded in such a manner, please do
                not use the telephone services provided by BHAGVA. You
                explicitly agree and permit BHAGVA to record all this
                information when you avail the telephony services through the
                BHAGVA provided phone lines on the BHAGVA Platform.
              </p>
              <p>
                b. You agree to use the Services only for purposes that are
                permitted by (a) the Terms and (b) any applicable law,
                regulation or generally accepted practices or guidelines in the
                relevant jurisdictions.
              </p>
              <p>
                c. You agree to use the data owned by BHAGVA (as available on
                the Services or through any other means like API etc.) only for
                personal use/purposes and not for any commercial use (other than
                in accordance with 'Claim Your Business Listing' access) unless
                agreed to by/with BHAGVA in writing.
              </p>
              <p>
                d. You agree not to access (or attempt to access) any of the
                Services by any means other than the interface that is provided
                by BHAGVA, unless you have been specifically allowed to do so,
                by way of a separate agreement with BHAGVA. You specifically
                agree not to access (or attempt to access) any of the Services
                through any automated means (including use of scripts or web
                crawlers) and shall ensure that you comply with the instructions
                set out in any robots.txt file present on the Services.
              </p>
              <p>
                e. You agree that you will not engage in any activity that
                interferes with or disrupts the Services (or the servers and
                networks which are connected to the Services). You shall not
                delete or revise any material or information posted by any other
                Customer(s), shall not engage in spamming, including but not
                limited to any form of emailing, posting or messaging that is
                unsolicited.
              </p>
              <h5>VIII. Content</h5>
              <h6>1. Ownership of BHAGVA Content and Proprietary Rights</h6>
              <p>
                a. We are the sole and exclusive copyright owners of the
                Services and our Content. We also exclusively own the
                copyrights, trademarks, service marks, logos, trade names, trade
                dress and other intellectual and proprietary rights throughout
                the world (the "IP Rights") associated with the Services and
                BHAGVA Content, which may be protected by copyright, patent,
                trademark and other applicable intellectual property and
                proprietary rights and laws. You acknowledge that the Services
                contain original works and have been developed, compiled,
                prepared, revised, selected, and arranged by us and others
                through the application of methods and standards of judgment
                developed and applied through the expenditure of substantial
                time, effort, and money and constitutes valuable intellectual
                property of us and such others. You further acknowledge that the
                Services may contain information which is designated as
                confidential by BHAGVA and that you shall not disclose such
                information without BHAGVA's prior written consent.
              </p>
              <p>
                b. You agree to protect BHAGVA's proprietary rights and the
                proprietary rights of all others having rights in the Services
                during and after the term of this agreement and to comply with
                all reasonable written requests made by us or our suppliers and
                licensors of content or otherwise to protect their and others'
                contractual, statutory, and common law rights in the Services.
                You acknowledge and agree that BHAGVA (or BHAGVA's licensors)
                own all legal right, title and interest in and to the Services,
                including any IP Rights which subsist in the Services (whether
                those rights happen to be registered or not, and wherever in the
                world those rights may exist). You further acknowledge that the
                Services may contain information which is designated as
                confidential by BHAGVA and that you shall not disclose such
                information without BHAGVA's prior written consent. Unless you
                have agreed otherwise in writing with BHAGVA, nothing in the
                Terms gives you a right to use any of BHAGVA's trade names,
                trademarks, service marks, logos, domain names, and other
                distinctive brand features.
              </p>
              <p>
                c. You agree not to use any framing techniques to enclose any
                trademark or logo or other proprietary information of BHAGVA; or
                remove, conceal or obliterate any copyright or other proprietary
                notice or source identifier, including without limitation, the
                size, colour, location or style of any proprietary mark(s). Any
                infringement shall lead to appropriate legal proceedings against
                you at an appropriate forum for seeking all available/possible
                remedies under applicable laws of the country of violation. You
                cannot modify, reproduce, publicly display or exploit in any
                form or manner whatsoever any of the BHAGVA's Content in whole
                or in part except as expressly authorized by BHAGVA.
              </p>
              <p>
                d. To the fullest extent permitted by applicable law, we neither
                warrant nor represent that your use of materials displayed on
                the Services will not infringe rights of third parties not owned
                by or affiliated with us. You agree to immediately notify us
                upon becoming aware of any claim that the Services infringe upon
                any copyright trademark, or other contractual, intellectual,
                statutory, or common law rights by following the instructions
                contained below in section XVI.
              </p>
              <h6>2. Your License to BHAGVA Content</h6>
              <p>
                a. We grant you a personal, limited, non-exclusive and
                non-transferable license to access and use the Services only as
                expressly permitted in these Terms. You shall not use the
                Services for any illegal purpose or in any manner inconsistent
                with these Terms. You may use information made available through
                the Services solely for your personal, non-commercial use. You
                agree not to use, copy, display, distribute, modify, broadcast,
                translate, reproduce, reformat, incorporate into advertisements
                and other works, sell, promote, create derivative works, or in
                any way exploit or allow others to exploit any of BHAGVA Content
                in whole or in part except as expressly authorized by us. Except
                as otherwise expressly granted to you in writing, we do not
                grant you any other express or implied right or license to the
                Services, BHAGVA Content or our IP Rights.
              </p>
              <p>
                b. Any violation by you of the license provisions contained in
                this Section may result in the immediate termination of your
                right to use the Services, as well as potential liability for
                copyright and other IP Rights infringement depending on the
                circumstances.
              </p>
              <h6>3. BHAGVA License to Your or Customer Content</h6>
              <p>
                In consideration of availing the Services on the BHAGVA Platform
                and by submitting Your Content, you hereby irrevocably grant
                BHAGVA a perpetual, irrevocable, world-wide, non-exclusive,
                fully paid and royalty-free, assignable, sub-licensable and
                transferable license and right to use Your Content (including
                content shared by any business user having access to a 'Business
                Page' to manage claimed business listings or otherwise) and all
                IP Rights therein for any purpose including API partnerships
                with third parties and in any media existing now or in future.
                By "use" we mean use, copy, display, distribute, modify,
                translate, reformat, incorporate into advertisements and other
                works, analyze, promote, commercialize, create derivative works,
                and in the case of third party services, allow their users and
                others to do the same. You grant us the right to use the name or
                username that you submit in connection with Your Content. You
                irrevocably waive, and cause to be waived, any claims and
                assertions of moral rights or attribution with respect to Your
                Content brought against BHAGVA or its Customers, any third party
                services and their users.
              </p>

              <h6>4. Representations Regarding Your or Customer Content</h6>
              <p>
                a. You are responsible for Your Content. You represent and
                warrant that you are the sole author of, own, or otherwise
                control all of the rights of Your Content or have been granted
                explicit permission from the rights holder to submit Your
                Content; Your Content was not copied from or based in whole or
                in part on any other content, work, or website; Your Content was
                not submitted via the use of any automated process such as a
                script bot; use of Your Content by us, third party services, and
                our and any third party users will not violate or infringe any
                rights of yours or any third party; Your Content is truthful and
                accurate; and Your Content does not violate the Guidelines and
                Policies or any applicable laws
              </p>
              <p>
                b. If Your Content is a review, you represent and warrant that
                you are the sole author of that review; the review reflects an
                actual dining experience that you had; you were not paid or
                otherwise remunerated in connection with your authoring or
                posting of the review; and you had no financial, competitive, or
                other personal incentive to author or post a review that was not
                a fair expression of your honest opinion.
              </p>
              <p>
                c. You assume all risks associated with Your Content, including
                anyone's reliance on its quality, accuracy, or reliability, or
                any disclosure by you of information in Your Content that makes
                you personally identifiable. While we reserve the right to
                remove Content, we do not control actions or Content posted by
                our Customers and do not guarantee the accuracy, integrity or
                quality of any Content. You acknowledge and agree that Content
                posted by Customers and any and all liability arising from such
                Content is the sole responsibility of the Customer who posted
                the content, and not BHAGVA.
              </p>
              <h6>5. Content Removal</h6>
              <p>
                We reserve the right, at any time and without prior notice, to
                remove, block, or disable access to any Content that we, for any
                reason or no reason, consider to be objectionable, in violation
                of the Terms or otherwise harmful to the Services or our
                Customers in our sole discretion. Subject to the requirements of
                applicable law, we are not obligated to return any of Your
                Content to you under any circumstances. Further, the Merchant
                reserves the right to delete any images and pictures forming
                part of Customer Content, from such Merchant's listing page at
                its sole discretion.
              </p>
              <h6>6. Third Party Content and Links</h6>
              <p>
                a. Some of the content available through the Services may
                include or link to materials that belong to third parties, such
                as third-party reservation services or online food
                delivery/ordering. Please note that your use of such third-party
                services will be governed by the terms of service and privacy
                policy applicable to the corresponding third party. We may
                obtain business addresses, phone numbers, and other contact
                information from third party vendors who obtain their data from
                public sources.
              </p>
              <p>
                b. We have no control over, and make no representation or
                endorsement regarding the accuracy, relevancy, copyright
                compliance, legality, completeness, timeliness or quality of any
                product, services, advertisements and other content appearing in
                or linked to from the Services. We do not screen or investigate
                third party material before or after including it on our
                Services.
              </p>
              <p>
                c. We reserve the right, in our sole discretion and without any
                obligation, to make improvements to, or correct any error or
                omissions in, any portion of the content accessible on the
                Services. Where appropriate, we may in our sole discretion and
                without any obligation, verify any updates, modifications, or
                changes to any content accessible on the Services, but shall not
                be liable for any delay or inaccuracies related to such updates.
                You acknowledge and agree that BHAGVA is not responsible for the
                availability of any such external sites or resources, and does
                not endorse any advertising, products or other materials on or
                available from such web sites or resources.
              </p>
              <p>
                d. Third party content, including content posted by our
                Customers, does not reflect our views or that of our parent,
                subsidiary, affiliate companies, branches, employees, officers,
                directors, or shareholders. In addition, none of the content
                available through the Services is endorsed or certified by the
                providers or licensors of such third party content. We assume no
                responsibility or liability for any of Your Content or any third
                party content.
              </p>
              <p>
                e. You further acknowledge and agree that BHAGVA is not liable
                for any loss or damage which may be incurred by you as a result
                of the availability of those external sites or resources, or as
                a result of any reliance placed by you on the completeness,
                accuracy or existence of any advertising, products or other
                materials on, or available from, such websites or resources.
                Without limiting the generality of the foregoing, we expressly
                disclaim any liability for any offensive, defamatory, illegal,
                invasive, unfair, or infringing content provided by third
                parties.
              </p>
              <h6>7. Customer Reviews</h6>
              <p>
                a. Customer reviews or ratings for Merchants do not reflect the
                opinion of BHAGVA. BHAGVA receives multiple reviews or ratings
                for Merchants by Customers, which reflect the opinions of the
                Customers. It is pertinent to state that each and every review
                posted on BHAGVA is the personal opinion of the
                Customer/reviewer only. BHAGVA is a neutral platform, which
                solely provides a means of communication between
                Customers/reviewers including Customers or Merchant
                owners/representatives with access to Business Page. The
                advertisements published on the BHAGVA Platform are independent
                of the reviews received by such advertisers.
              </p>
              <p>
                b. We are a neutral platform and we don't arbitrate disputes,
                however in case if someone writes a review that the Merchant
                does not consider to be true, the best option for the Merchant
                representative would be to contact the reviewer or post a public
                response in order to clear up any misunderstandings. If the
                Merchant believes that any particular Customer's review violates
                any of the BHAGVA' policies, the Merchant may write to us at
                ............@Bhagva.in and bring such violation to our
                attention. BHAGVA may remove the review in its sole discretion
                if review is in violation of the Terms, or content guidelines
                and policies or otherwise harmful to the Services
              </p>
            </section>
            <section>
              <h5>IX. Content guidelines and privacy policy</h5>
              <div>
                <h6>1. Content Guidelines</h6>
                <p>
                  You represent that you have read, understood and agreed to our
                  Guidelines and Polices related to Content.
                </p>
                <p>
                  <strong>2. Privacy Policy</strong>
                </p>
                <p>
                  You represent that you have read, understood and agreed to our
                  Privacy Policy. Please note that we may disclose information
                  about you to third parties or government authorities if we
                  believe that such a disclosure is reasonably necessary to (i)
                  take action regarding suspected illegal activities; (ii)
                  enforce or apply our Terms and Privacy Policy; (iii) comply
                  with legal process or other government inquiry, such as a
                  search warrant, subpoena, statute, judicial proceeding, or
                  other legal process/notice served on us; or (iv) protect our
                  rights, reputation, and property, or that of our Customers,
                  affiliates, or the general public.
                </p>
              </div>
            </section>
            <section>
              <h5>X. Restrictions on use</h5>
              <div>
                <p>
                  1. Without limiting the generality of these Terms, in using
                  the Services, you specifically agree not to post or transmit
                  any content (including review) or engage in any activity that,
                  in our sole discretion:
                </p>
                <p>a. Violates our Guidelines and Policies;</p>
                <p>
                  b. Is harmful, threatening, abusive, harassing, tortious,
                  indecent, defamatory, discriminatory, vulgar, profane,
                  obscene, libellous, hateful or otherwise objectionable,
                  invasive of another's privacy, relating or encouraging money
                  laundering or gambling;
                </p>
                <p>
                  c. Constitutes an inauthentic or knowingly erroneous review,
                  or does not address the goods and services, atmosphere, or
                  other attributes of the business you are reviewing.
                </p>
                <p>
                  d. Contains material that violates the standards of good taste
                  or the standards of the Services;
                </p>
                <p>
                  e. Violates any third-party right, including, but not limited
                  to, right of privacy, right of publicity, copyright,
                  trademark, patent, trade secret, or any other intellectual
                  property or proprietary rights;
                </p>
                <p>
                  f. Accuses others of illegal activity, or describes physical
                  confrontations;
                </p>
                <p>
                  g. Alleges any matter related to health code violations
                  requiring healthcare department reporting. Refer to our
                  Guidelines and Policies for more details about health code
                  violations.
                </p>
                <p>
                  h. Is illegal, or violates any federal, state, or local law or
                  regulation (for example, by disclosing or trading on inside
                  information in violation of securities law);
                </p>
                <p>i. Attempts to impersonate another person or entity;</p>
                <p>
                  j. Disguises or attempts to disguise the origin of Your
                  Content, including but not limited to by: (i) submitting Your
                  Content under a false name or false pretences; or (ii)
                  disguising or attempting to disguise the IP address from which
                  Your Content is submitted;
                </p>
                <p>
                  k. Constitutes a form of deceptive advertisement or causes, or
                  is a result of, a conflict of interest;
                </p>
                <p>
                  l. Is commercial in nature, including but not limited to spam,
                  surveys, contests, pyramid schemes, postings or reviews
                  submitted or removed in exchange for payment, postings or
                  reviews submitted or removed by or at the request of the
                  business being reviewed, or other advertising materials;
                </p>
                <p>
                  m. Asserts or implies that Your Content is in any way
                  sponsored or endorsed by us;
                </p>
                <p>
                  n. Contains material that is not in English or, in the case of
                  products or services provided in foreign languages, the
                  language relevant to such products or services;
                </p>
                <p>
                  o. Falsely states, misrepresents, or conceals your affiliation
                  with another person or entity;
                </p>
                <p>
                  p. Accesses or uses the account of another customer without
                  permission;
                </p>
                <p>
                  q. Distributes computer viruses or other code, files, or
                  programs that interrupt, destroy, or limit the functionality
                  of any computer software or hardware or electronic
                  communications equipment;
                </p>
                <p>
                  r. Interferes with, disrupts, or destroys the functionality or
                  use of any features of the Services or the servers or networks
                  connected to the Services;
                </p>
                <p>
                  s. "Hacks" or accesses without permission our proprietary or
                  confidential records, records of another Customer, or those of
                  anyone else;
                </p>
                <p>
                  t. Violates any contract or fiduciary relationship (for
                  example, by disclosing proprietary or confidential information
                  of your employer or client in breach of any employment,
                  consulting, or non-disclosure agreement);
                </p>
                <p>
                  u. Decompiles, reverse engineers, disassembles or otherwise
                  attempts to derive source code from the Services;
                </p>
                <p>
                  v. Removes, circumvents, disables, damages or otherwise
                  interferes with security-related features, or features that
                  enforce limitations on use of, the Services;
                </p>
                <p>
                  w. Violates the restrictions in any robot exclusion headers on
                  the Services, if any, or bypasses or circumvents other
                  measures employed to prevent or limit access to the Services;
                </p>
                <p>
                  x. Collects, accesses, or stores personal information about
                  other Customers of the Services;
                </p>
                <p>y. Is posted by a bot;</p>
                <p>z. Harms minors in any way;</p>
                <p>
                  aa. Threatens the unity, integrity, defense, security or
                  sovereignty of India or of the country of use, friendly
                  relations with foreign states, or public order or causes
                  incitement to the commission of any cognizable offence or
                  prevents investigation of any offence or is insulting any
                  other nation;
                </p>
                <p>
                  ab. Modifies, copies, scrapes or crawls, displays, publishes,
                  licenses, sells, rents, leases, lends, transfers or otherwise
                  commercialize any rights to the Services or Our Content; or
                </p>
                <p>ac. Attempts to do any of the foregoing.</p>
                <p>
                  ad. Is patently false and untrue, and is written or published
                  in any form, with the intent to mislead or harass a person,
                  entity or agency for financial gain or to cause any injury to
                  any person;
                </p>
                <p>
                  2. You acknowledge that BHAGVA has no obligation to monitor
                  your – or anyone else's – access to or use of the Services for
                  violations of the Terms, or to review or edit any content.
                  However, we have the right to do so for the purpose of
                  operating and improving the Services (including without
                  limitation for fraud prevention, risk assessment,
                  investigation and customer support purposes), to ensure your
                  compliance with the Terms and to comply with applicable law or
                  the order or requirement of legal process, a court, consent
                  decree, administrative agency or other governmental body.
                </p>
                <p>
                  3. You hereby agree and assure BHAGVA that the BHAGVA
                  Platform/Services shall be used for lawful purposes only and
                  that you will not violate laws, regulations, ordinances or
                  other such requirements of any applicable Central, Federal
                  State or local government or international law(s). You shall
                  not upload, post, email, transmit or otherwise make available
                  any unsolicited or unauthorized advertising, promotional
                  materials, junk mail, spam mail, chain letters or any other
                  form of solicitation, encumber or suffer to exist any lien or
                  security interest on the subject matter of these Terms or to
                  make any representation or warranty on behalf of BHAGVA in any
                  form or manner whatsoever.
                </p>
                <p>
                  4. You hereby agree and assure that while communicating on the
                  BHAGVA Platform including but not limited to giving cooking
                  instructions to the Merchants, communicating with our support
                  agents on chat support or with the Delivery Partners, through
                  any medium, You shall not use abusive and derogatory language
                  and/or post any objectionable information that is unlawful,
                  threatening, defamatory, or obscene. In the event you use
                  abusive language and/or post objectionable information, BHAGVA
                  reserves the right to suspend the chat support service and/or
                  block your access and usage of the BHAGVA Platform, at any
                  time with or without any notice.
                </p>
                <p>
                  5. Any Content uploaded by you, shall be subject to relevant
                  laws of India and of the country of use and may be disabled,
                  or and may be subject to investigation under applicable laws.
                  Further, if you are found to be in non-compliance with the
                  laws and regulations, these terms, or the privacy policy of
                  the BHAGVA Platform, BHAGVA shall have the right to
                  immediately block your access and usage of the BHAGVA Platform
                  and BHAGVA shall have the right to remove any non-compliant
                  content and or comment forthwith, uploaded by you and shall
                  further have the right to take appropriate recourse to such
                  remedies as would be available to it under various statutes.
                </p>
              </div>
            </section>
            <section>
              <h5>XI. Customer feedback</h5>
              <p>
                1. If you share or send any ideas, suggestions, changes or
                documents regarding BHAGVA's existing business ("Feedback"), you
                agree that (i) your Feedback does not contain the confidential,
                secretive or proprietary information of third parties, (ii)
                BHAGVA is under no obligation of confidentiality with respect to
                such Feedback, and shall be free to use the Feedback on an
                unrestricted basis (iii) BHAGVA may have already received
                similar Feedback from some other Customer or it may be under
                consideration or in development, and (iv) By providing the
                Feedback, you grant us a binding, non-exclusive, royalty-free,
                perpetual, global license to use, modify, develop, publish,
                distribute and sublicense the Feedback, and you irrevocably
                waive, against BHAGVA and its Customers any claims/assertions,
                whatsoever of any nature, with regard to such Feedback.
              </p>
              <p>
                2. Please provide only specific Feedback on BHAGVA's existing
                products or marketing strategies; do not include any ideas that
                BHAGVA's policy will not permit it to accept or consider.
              </p>
              <p>
                3. Notwithstanding the abovementioned clause, BHAGVA or any of
                its employees do not accept or consider unsolicited ideas,
                including ideas for new advertising campaigns, new promotions,
                new or improved products or technologies, product enhancements,
                processes, materials, marketing plans or new product names.
                Please do not submit any unsolicited ideas, original creative
                artwork, suggestions or other works ("Submissions") in any form
                to BHAGVA or any of its employees.
              </p>
              <p>
                4. The purpose of this policy is to avoid potential
                misunderstandings or disputes when BHAGVA's products or
                marketing strategies might seem similar to ideas submitted to
                BHAGVA. If, despite our request to not send us your ideas, you
                still submit them, then regardless of what your letter says, the
                following terms shall apply to your Submissions.
              </p>
              <p>
                5. Terms of Idea Submission You agree that: (1) your Submissions
                and their Contents will automatically become the property of
                BHAGVA, without any compensation to you; (2) BHAGVA may use or
                redistribute the Submissions and their contents for any purpose
                and in any way; (3) there is no obligation for BHAGVA to review
                the Submission; and (4) there is no obligation to keep any
                Submissions confidential.
              </p>
            </section>
            <section>
              <h5>XII. Advertising</h5>
              <p>
                1. Some of the Services are supported by advertising revenue and
                may display advertisements and promotions. These advertisements
                may be targeted to the content of information stored on the
                Services, queries made through the Services or other
                information. The manner, mode and extent of advertising by
                BHAGVA on the Services are subject to change without specific
                notice to you. In consideration for BHAGVA granting you access
                to and use of the Services, you agree that BHAGVA may place such
                advertising on the Services.
              </p>
              <p>
                2. Part of the site may contain advertising information or
                promotional material or other material submitted to BHAGVA by
                third parties or Customers. Responsibility for ensuring that
                material submitted for inclusion on the BHAGVA Platform or
                mobile apps complies with applicable international and national
                law is exclusively on the party providing the
                information/material. Your correspondence or business dealings
                with, or participation in promotions of, advertisers other than
                BHAGVA found on or through the BHAGVA Platform and or mobile
                apps, including payment and delivery of related goods or
                services, and any other terms, conditions, warranties or
                representations associated with such dealings, shall be solely
                between you and such advertiser. BHAGVA will not be responsible
                or liable for any error or omission, inaccuracy in advertising
                material or any loss or damage of any sort incurred as a result
                of any such dealings or as a result of the presence of such
                other advertiser(s) on the BHAGVA Platform and mobile
                application.
              </p>
              <p>
                3. For any information related to a charitable campaign
                ("Charitable Campaign") sent to Customers and/or displayed on
                the BHAGVA Platform where Customers have an option to donate
                money by way of (a) payment on a third party website; or (b)
                depositing funds to a third party bank account, BHAGVA is not
                involved in any manner in the collection or utilization of funds
                collected pursuant to the Charitable Campaign. BHAGVA does not
                accept any responsibility or liability for the accuracy,
                completeness, legality or reliability of any information related
                to the Charitable Campaign. Information related to the
                Charitable Campaign is displayed for informational purposes only
                and Customers are advised to do an independent verification
                before taking any action in this regard.
              </p>
            </section>
            <section>
              <h5>
                XIII. Additional Terms and Conditions for Customers using the
                various services offered by BHAGVA:
              </h5>
              <h6>1. ONLINE ORDERING:</h6>
              <p>
                a. BHAGVA provides online ordering services by entering into
                contractual arrangements with Merchant partners (“Merchant
                Partners”) and Stores (as defined below) on a
                principal-to-principal basis for the purpose of listing their
                menu items or the Products (as defined below) for online
                ordering by the Customers on the BHAGVA Platform.
              </p>
              <p>
                b. The Customers can access the menu items or Products listed on
                the BHAGVA Platform and place online orders against the Merchant
                Partner(s)/Store(s) through BHAGVA.
              </p>
              <p>
                c. Your request to order food and beverages or Products from a
                Merchant Partner or a Store page on the BHAGVA Platform shall
                constitute an unconditional and irrevocable authorization issued
                in favour of BHAGVA to place online orders for food and
                beverages or Products against the Merchant Partner(s)/Store(s)
                on your behalf.
              </p>
              <p>
                d. Delivery of an order placed by you through the BHAGVA
                Platform may either be undertaken directly by the Merchant
                Partner or the Store against whom you have placed an order, or
                facilitated by BHAGVA through a third-party who may be available
                to provide delivery services to you (“Delivery Partners”). In
                both these cases, BHAGVA is merely acting as an intermediary
                between you and the Delivery Partners, or you and the Merchant
                Partner or the Store, as the case may be.
              </p>
              <p>
                e. The acceptance by a Delivery Partner of undertaking delivery
                of your order shall constitute a contract of service under the
                Consumer Protection Act, 2019 or any successor legislations,
                between you and the Delivery Partner, to which BHAGVA is not a
                party under any applicable law. It is clarified that BHAGVA does
                not provide any delivery or logistics services and only enables
                the delivery of food and beverages or Products ordered by the
                Customers through the BHAGVA Platform by connecting the
                Customers with the Delivery Partners or the Merchant Partners or
                the Store, as the case may be.
              </p>
              <p>
                f. Where BHAGVA is facilitating delivery of an order placed by
                you, BHAGVA shall not be liable for any acts or omissions on
                part of the Delivery Partner including deficiency in service,
                wrong delivery of order, time taken to deliver the order, order
                package tampering, etc.
              </p>
              <p>
                g. You may be charged a delivery fee for delivery of your order
                by the Delivery Partner or the Merchant Partner or the Store, as
                the Delivery Partner or the Merchant Partner or the Store may
                determine (“Delivery Charges"). You agree that BHAGVA is
                authorized to collect, on behalf of the Merchant Partner or the
                Delivery Partner or the Store, the Delivery Charges for the
                delivery service provided by the Merchant Partner or the Store
                or the Delivery Partner, as the case may be. The Delivery
                Charges may vary from order to order, which may be determined on
                multiple factors which shall include but not be limited to
                Merchant Partner / Store, order value, distance, time of the
                day. BHAGVA will inform you of the Delivery Charges that may
                apply to you, provided you will be responsible for Delivery
                Charges incurred for your order regardless of your awareness of
                such Delivery Charges.
              </p>
              <p>
                h. In addition to the Delivery Charges, you may also be charged
                an amount towards delivery surge for delivery of your order
                facilitated by the Delivery Partner or the Merchant Partner or
                the Store, which is determined on the basis of various factors
                including but not limited to distance covered, time taken,
                demand for delivery, real time analysis of traffic and weather
                conditions, seasonal peaks or such other parameters as may be
                determined from time to time (“Delivery Surge"). You agree that
                BHAGVA is authorized to collect, on behalf of the Merchant
                Partner or the Delivery Partner or the Store, the Delivery Surge
                for the delivery service provided by the Merchant Partner or the
                Store or the Delivery Partner, as the case may be. The Delivery
                Surge may vary from order to order, which may be determined on
                multiple factors which shall include but not be limited to
                Merchant Partner / Store, order value, distance, demand during
                peak hours. BHAGVA will use reasonable efforts to inform you of
                the Delivery Surge that may apply to you, provided you will be
                responsible for the Delivery Surge incurred for your order
                regardless of your awareness of such Delivery Surge.
              </p>
              <p>
                i. In respect of the order placed by You, BHAGVA shall issue
                documents like order summary, tax invoices, etc. as per the
                applicable legal regulations and common business practices.
              </p>
              <h6>B. Online Ordering with Merchant Partners:</h6>
              <p>
                a. All prices listed on the BHAGVA Platform are provided by the
                Merchant Partner at the time of publication on the BHAGVA
                Platform and have been placed as received from the Merchant
                Partner. While we take great care to keep them up to date, the
                final price charged to you by the Merchant Partner may change at
                the time of delivery. In the event of a conflict between price
                on the BHAGVA Platform and price charged by the Merchant
                Partner, the price charged by the Merchant Partner shall be
                deemed to be the correct price except Delivery Charge of BHAGVA.
              </p>
              <p>
                b. On Time or Free: For Customers in India, You may opt for
                on-time services offered by BHAGVA, for an additional
                non-refundable cost, at select Merchant Partners. However You
                acknowledge that such services are facilitated by BHAGVA on a
                best effort basis, hence should your order fail to reach you on
                or prior to the Promise Time, you would be eligible to claim and
                receive a Coupon worth order value or up-to INR 250, whichever
                is lower. You will be required to claim the Coupon within twenty
                four (24) hours from the time such Order is delivered to You
                failing which your eligibility to receive the Coupon will
                expire. Further the validity period of the Coupon would be 7
                (seven) days from receipt thereof. Notwithstanding anything set
                out herein above, You shall not be eligible to receive the
                Coupon if:
              </p>
              <p>
                i. Delay on the Promise Time is for unforeseen reasons eg.
                strikes, natural disaster, Merchant Partner’s inability to
                provide the Order.
              </p>
              <p>
                ii. You change, edit, modify or cancel such Order or do any such
                act that has the effect of changing, editing or modifying such
                order including but not limited to adding or changing the items
                ordered, receiving delivery at a location other than the one
                indicated at the time of placing of the order etc.
              </p>
              <p>
                iii. You indulge in actions intended to delay the order
                including but not limited to asking the Delivery Partner to
                delay the Order, becoming unresponsive on call etc.
              </p>
              <p>
                iv. The order is a bulk order (as per BHAGVA’s standard order
                size)
              </p>
              <p>
                For the purpose of this clause, words capitalized shall have the
                following meaning: <strong>"Promise Time"</strong> shall mean
                the time period between the Merchant Partner accepting the order
                and the Delivery Partner reaching within 100 metre radius from
                Your location or first barrier point (security guard/reception
                etc.) whichever is further. “Coupon" shall mean one- time code
                generated by BHAGVA for delay in Promise Time to be used as per
                applicable conditions.
              </p>
              <p>
                c. <strong>BHAGVA Pro for Online Ordering</strong>: BHAGVA Pro
                members in India can avail Discounts (as defined below) extended
                by Partner Merchants (as defined below) on home delivery. Please
                refer to the terms and conditions set out below in clause 3.
              </p>
              <p>
                <strong>d. Promise Time</strong>
              </p>
              <p>
                i. You may place an online order with a Merchant Partner to be
                delivered to someone else, your loved ones (may or may not be a
                BHAGVA customer) (“Gift Recipient”) as a gift (“Gift Order”).{" "}
              </p>
              <p>
                ii. To place a Gift Order, You will be required to provide the
                Gift Recipient’s contact details, such as name, phone number,
                address or any other information that may be reasonably required
                (“Contact Information”) to enable the Merchant Partner, Delivery
                Partner deliver the Gift Order.
              </p>
              <p>
                iii. By availing BHAGVA Gift feature, You warrant and represent
                that You have obtained the Gift Recipient’s consent to provide
                BHAGVA with the Contact Information. You hereby further warrant
                and represent to indemnify and hold BHAGVA, its directors,
                employees, affiliates and subsidiaries and their respective
                directors, employees, harmless against any claims or disputes
                initiated by the Gift Recipient whose Contact Information was
                provided by You for the purpose of placing the Gift Order.
              </p>
              <p>
                iv. By placing a Gift Order, You hereby irrevocably undertake to
                be responsible for any refusal made by the Gift Recipient or for
                any prejudice suffered by the latter or BHAGVA.
              </p>
              <p>
                v. In case the Gift Recipient is, non contactable, the Merchant
                Partner, Delivery Partner or we may contact You for further
                assistance.
              </p>
              <p>
                vi. You may send a message with the Gift order which we will
                endeavour to deliver, however sometimes, the message can’t be
                sent.
              </p>
              <p>
                vii. You will not be charged any additional payment for Gift
                Order. All charges will be in a similar manner as for a regular
                placed online order.
              </p>
              <p>
                viii. In the event, the Gift Recipient wishes to raise any issue
                with regard to the Gift Order, they can do so by requesting you
                to do it or via the chat support on the BHAGVA Platform or write
                to us at ………………...
              </p>
              <p>
                ix. Any refund on the Gift order shall be provided to the sender
                and the Gift Recipient shall not receive any benefit.
              </p>
              <p>
                {" "}
                x. You explicitly and unambiguously consent to the collection,
                use and transfer, in electronic or other forms, of personal
                information for the purposes of BHAGVA Gift. You will be
                required to share certain personal information with BHAGVA
                including your name, phone number, email address, payment
                details and BHAGVA will use these details in accordance with the
                Privacy Policy published on ………………….
              </p>{" "}
              <p>
                xi. All other terms and conditions for online ordering provided
                herein under clause XIII[1] shall apply as is.
              </p>
              <div>
                <h6>2. General Terms and Conditions</h6>
                <p>
                  a. BHAGVA is not a manufacturer, seller, or distributor of
                  food and beverages or Products and merely places an order
                  against the Merchant Partner(s)/Store(s) on behalf of the
                  Customers pursuant to the unconditional and irrevocable
                  authority granted by the Customers to BHAGVA, and facilitates
                  the sale and purchase of food and beverages or Products
                  between Customers and Merchant Partners/Store(s), under the
                  contract for sale and purchase of food and beverages or
                  Products between the Customers and Merchant Partners/Store(s).
                </p>
                <p>
                  b. BHAGVA shall not be liable for any acts or omissions on the
                  part of the Merchant Partner/Store(s) including deficiency in
                  service, wrong delivery of order / order mismatch, quality,
                  incorrect pricing, deficient quantity, time taken to prepare
                  or deliver the order, etc.
                </p>
                <p>
                  c. The Merchant Partner(s)/Store(s) shall be solely
                  responsible for any warranty/guarantee of the food and
                  beverages or Products sold to the Customer and in no event
                  shall be the responsibility of BHAGVA.
                </p>
                <p>
                  d. For the Customers in India, it is hereby clarified by
                  BHAGVA that the liability of any violation of the applicable
                  rules and regulations made thereunder shall solely rest with
                  the sellers/brand owners, vendors, Merchant
                  Partner(s)/Store(s), importers, or manufacturers of the food
                  products, Products, or any Pre-Packed Goods accordingly. For
                  the purpose of clarity, Pre-Packed Goods shall mean the food
                  and beverages items which are placed in a package of any
                  nature, in such a manner that the contents cannot be changed
                  without tampering it and which is ready for sale to the
                  customer or as may be defined under the Food Safety and
                  Standards Act, 2006 from time to time.
                </p>
                <p>
                  e. Please note that some of the food and beverages or Products
                  may be suitable for certain ages only. You should check the
                  dish you are ordering and read its description, if provided,
                  prior to placing your order. BHAGVA shall not be liable in the
                  event the food and beverages or the Product ordered by You
                  does not meet your dietary or any other requirements and/or
                  restrictions.
                </p>
                <p>
                  f. While placing an order, you shall be required to provide
                  certain details, including without limitation, contact number
                  and delivery address. You agree to take particular care when
                  providing these details and warrant that these details are
                  accurate and complete at the time of placing an Order. By
                  providing these details, you express your acceptance to
                  BHAGVA's terms and privacy policies.
                </p>
                <p>
                  g. You or any person instructed by you shall not resell food
                  and beverages or Products purchased via the BHAGVA Platform.
                </p>
                <p>
                  h. The total price for food ordered, including the Delivery
                  Charges and other charges, will be displayed on the BHAGVA
                  Platform when you place your order, which may be rounded up to
                  the nearest amount. Customers shall make full payment towards
                  such food or Products ordered via the BHAGVA Platform.
                </p>
                <p>
                  i. Any amount that may be charged to you by BHAGVA over and
                  above the order value shall be inclusive of applicable taxes.
                </p>
                <p>
                  j. Delivery periods/Takeaway time quoted at the time of
                  ordering are approximate only and may vary.
                </p>
                <p>
                  k. Personal Promo code can only be used by You subject to such
                  terms and conditions set forth by BHAGVA from time to time.
                </p>
                <h6>4. Cancellation and Refund Policy</h6>
                <p>
                  i. You acknowledge that (1) your cancellation, or attempted or
                  purported cancellation of an order or (2) cancellation due to
                  reasons not attributable to BHAGVA, that is, in the event you
                  provide incorrect particulars, contact number, delivery
                  address etc., or that you were unresponsive, not reachable or
                  unavailable for fulfillment of the services offered to you,
                  shall amount to breach of your unconditional and irrevocable
                  authorization in favour of BHAGVA to place that order against
                  the Merchant Partners/Store(s) on your behalf (“Authorization
                  Breach"). In the event you commit an Authorization Breach, you
                  shall be liable to pay the liquidated damages of an amount
                  equivalent to the order value. You hereby authorize BHAGVA to
                  deduct or collect the amount payable as liquidated damages
                  through such means as BHAGVA may determine in its discretion,
                  including without limitation, by deducting such amount from
                  any payment made towards your next Order.
                </p>
                <p>
                  ii. There may be cases where BHAGVA is either unable to accept
                  your order or cancels the order, due to reasons including
                  without limitation, technical errors, unavailability of the
                  item(s) ordered, or any other reason attributable to BHAGVA,
                  Merchant Partner/Store or Delivery Partner. In such cases,
                  BHAGVA shall not charge a cancellation charge from you. If the
                  order is cancelled after payment has been charged and you are
                  eligible for a refund of the order value or any part thereof,
                  the said amount will be reversed to you.
                </p>
                <p>
                  iii. No replacement / refund / or any other resolution will be
                  provided without Merchant Partner’s/Store(s)’ permission.
                </p>
                <p>
                  iv. Any complaint, with respect to the order which shall
                  include instances but not be limited to food spillage, foreign
                  objects in food, delivery of the wrong order or food and
                  beverages or Products, poor quality, You will be required to
                  share the proof of the same before any resolution can be
                  provided.
                </p>
                <p>
                  v. You shall not be entitled to a refund in case instructions
                  placed along with the order are not followed in the form and
                  manner You had intended. Instructions are followed by the
                  Merchant Partner /Store on a best-efforts basis.
                </p>
                <p>
                  vi. All refunds shall be processed in the same manner as they
                  are received, unless refunds have been provided to You in the
                  form of credits, refund amount will reflect in your account
                  based on respective banks policies.
                </p>
                <h6>5. BHAGVA PRO</h6>
                <p>
                  a. BHAGVA Pro: BHAGVA Pro is a membership-based program
                  available in select countries which allows its members to
                  avail benefits on dine-in and online ordering offered by a
                  host of Merchants partnered with BHAGVA ("Partner Merchants").
                </p>
                <p>
                  b. BHAGVA Pro Membership:
                  <br />
                  i. For BHAGVA Pro members in India: As a member of BHAGVA Pro,
                  You will be entitled to avail Discount (as defined below) on
                  the Bill Value (as defined below), provided that the Bill
                  Value for such order is above the minimum value (if
                  applicable) as determined by the Partner Merchant and when You
                  pay the Bill Value via the BHAGVA Platform based on and
                  subject to the membership plan purchased by You via the BHAGVA
                  Platform read along with these Terms. For the purpose of
                  BHAGVA Pro, “Bill Value” shall mean the total amount set out
                  in the bill for food and beverages availed by the member at
                  the Partner Merchants, and shall not include applicable taxes,
                  Delivery Charges (i.e. Delivery Charges including any distance
                  fee or Delivery Surge), service charge, and other charges as
                  may be applicable and “Discount” includes but is not limited
                  to a percentage of discount or a flat discount.
                </p>
                <h6>6. BHAGVA Pro for Online Ordering</h6>
                <p>
                  i. As a member of BHAGVA Pro in India, you can avail Discount
                  on Order placed by You from partnered Merchants.
                </p>
                <p>
                  ii. The Discount cannot be clubbed with any other offers or
                  discounts or deals extended by the Partnered Merchant or
                  BHAGVA or any other third party.
                </p>
                <p>
                  iii. The Discount is not valid on menu items sold by the
                  Partnered Merchant at maximum retail price (MRP), combos, and
                  any other items being sold at discount by the Partner
                  Merchants.
                </p>
                <p>
                  iv. The Discount can be availed only for Orders placed for
                  home delivery.
                </p>
                <p>
                  e. BHAGVA Pro members will get special delivery offers at
                  applicable Partner Merchants which will be visible on BHAGVA
                  Platform.
                </p>
                <p>
                  f. Money Back Guarantee: For BHAGVA Pro members in India and
                  UAE, if a BHAGVA Pro member does not save the amount actually
                  paid for the purchase of BHAGVA Pro membership, such BHAGVA
                  Pro member will be entitled to a partial refund which shall be
                  determined as a proportion of the shortfall in the guaranteed
                  saving to the BHAGVA Pro membership fee paid by the BHAGVA Pro
                  member for the subscription. The money back guarantee is
                  applicable only to BHAGVA Pro members who have purchased an
                  annual membership before November 26, 2020. BHAGVA may at its
                  sole discretion refund the proportionate tax amount on such
                  refund. For the purpose of clarification, such amount shall be
                  refunded to the BHAGVA Pro member at the end of the membership
                  tenure. It is hereby clarified that in the event you cancel
                  your membership at any time during the validity of your
                  membership plan, you will not be eligible to receive a refund
                  on a pro-rata basis.
                </p>
                <p>
                  g. Update: The Partner Merchants may change from time to time
                  and the members are advised to keep a check on the updated
                  list of Partner Merchants from time to time at BHAGVA’s
                  Platform.
                </p>
                <p>
                  h. Fees: In order to avail BHAGVA Pro, members are required to
                  pay a membership fee which shall be based on the membership
                  plan opted by such member.
                </p>
                <p>
                  i. Payments: To purchase and/or renew your membership plan,
                  you can choose a payment method, as available on the 'Payment'
                  section of the BHAGVA Platform. Your access to BHAGVA Pro
                  shall be subject to receipt of successful payments by BHAGVA.
                  The membership fee shall be inclusive of all applicable taxes.
                  For some payment methods, the issuer may charge you certain
                  fees, such as foreign transaction fees or other fees, relating
                  to the processing of your payment method. BHAGVA shall require
                  additional information and/or documents from you in accordance
                  to the applicable local laws in your or as per the internal
                  requirements of BHAGVA.
                </p>
                <p>
                  j. Term: These Terms will begin on the date of purchase of the
                  membership plan and will be valid till such time your
                  membership plan expires.
                </p>
                <p>
                  k. Subscription and Renewal: The membership is
                  non-transferable and refundable (in the manner set out below)
                  once purchased. Your membership may be automatically renewed
                  upon expiry as per your already purchased membership plan. In
                  case of auto-renewal, You authorize us to debit your payment
                  method linked to BHAGVA Platform. In the event you do not wish
                  to renew the membership, you can cancel the membership for
                  renewal at any time during the validity of your membership
                  plan. When you request the cancellation of auto-renewal of
                  your membership, your membership plan will not be auto-renewed
                  and you can continue to access BHAGVA Pro during your existing
                  membership plan until the expiry of the validity period.
                </p>
                <p>
                  l. Cancellation and Refund of Membership Plan: In the event
                  you cancel your membership at any time during the validity of
                  your membership plan, you will be eligible to receive a refund
                  on a pro-rata basis. For the purpose of clarity, refund on a
                  pro-rata basis will be calculated from the month you place a
                  cancellation request. It is hereby further clarified that the
                  pro-rata refund shall only be for the membership plan for
                  which the member has paid and shall not include any period of
                  extension to the membership plan that a Customer may have
                  received on account of any referrals or campaigns. BHAGVA
                  reserves the right to cancel any members’ BHAGVA Pro
                  membership at any time and provide a pro-rated refund to the
                  membership fee (if any). BHAGVA Pro membership purchases from
                  third-party platforms may not be eligible for a pro-rata
                  refund. You are required to check the terms and conditions for
                  pro-rata refund at the time of purchasing the BHAGVA Pro
                  membership from third-party platforms.
                </p>
                <p>
                  m. Modification to BHAGVA Pro: BHAGVA reserves the right to
                  offer, alter, extend, or withdraw, as the case may be, any
                  offers or discounts or promotions extended by BHAGVA at any
                  time with or without giving any prior notice to the Customers.
                  In such cases, such revision will be updated on the BHAGVA
                  Platform accordingly.
                </p>
                <p>
                  n. BHAGVA reserves the right to terminate / suspend Your
                  membership to the BHAGVA Pro, if BHAGVA determines in its sole
                  discretion that (i) You have violated the terms of BHAGVA Pro
                  set out herein, (ii) have been involved in activities that are
                  in contravention of the BHAGVA Pro terms and/or any terms for
                  the usage of BHAGVA Platform; or (iii) have engaged in
                  activities which are fraudulent / unlawful in nature while
                  availing any of Services of BHAGVA.
                </p>
                <p>
                  o. Exclusion Days: BHAGVA Pro can be used any day of the week
                  during the opening hours of the Partner Merchant except on
                  days listed herein below ("Exclusion Days"). Exclusion period
                  applies from the start of operational hours for the day up
                  till 6 am of the following day. BHAGVA Pro will not be
                  applicable at Merchants in Yas Island (Abu Dhabi) during the
                  Formula 1 weekend. In addition, the Exclusion Days mentioned
                  herein BHAGVA Pro shall not be extended by Partner Merchant on
                  the days prohibited by law. The Partner Merchant, at its
                  discretion, may or may not extend BHAGVA Pro benefits to
                  Customers on an Exclusion Day. BHAGVA Pro members are advised
                  to make prior inquiry with the Partner Merchants and/or check
                  the BHAGVA Platform before visiting the Partner Merchant, to
                  confirm whether the BHAGVA Pro can be redeemed or not.
                </p>
                <div>
                  <ul>
                    <li> Exclusion Days for India</li>
                    <table>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>DATE</th>
                          <th>EVENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>December 31 and January 1</td>
                          <td>New Year's Eve/Day</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>As per lunar calendar</td>
                          <td>Pongal (applicable in Tamil Nadu only)</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>February 14</td>
                          <td>Valentine's Day</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>As per lunar calendar</td>
                          <td>Durga Puja (applicable in West Bengal only)</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>As per lunar calendar</td>
                          <td>Diwali Eve/Day</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>December 24 and December 25</td>
                          <td>Christmas Eve/Day</td>
                        </tr>
                      </tbody>
                    </table>
                  </ul>
                </div>
                <h6>8. Personal Information</h6>
                <p>
                  Members will be required to share certain personal information
                  with BHAGVA including their name, phone number, email address,
                  payment details, in order to purchase BHAGVA Pro. BHAGVA will
                  use these details in accordance with the Privacy Policy
                  published on [Insert URL].
                </p>

                <h6>9. Disclaimer</h6>
                <p>
                  The liability to extend the benefits under BHAGVA Pro rests
                  solely with the Partner Merchant and BHAGVA shall in no manner
                  be liable if the Partner Merchant does not honour the benefits
                  under BHAGVA Pro. The Partner Merchant reserves the right to
                  refuse service to anyone in accordance with its policies.
                  However, in the event the Partner Merchant refuses to honour
                  BHAGVA Pro in accordance with these Terms, please reach us via
                  Pro chat support on the BHAGVA app or write to us at [Insert
                  Contact Email] and we shall use our best endeavour to assist
                  you.
                </p>

                <h6>10. Compliance with Statutory Rules</h6>
                <p>
                  The Customer acknowledges that BHAGVA bears no responsibility
                  for the compliance with statutory rules, regulations, and
                  licenses by Partner Merchants. The Customer agrees that BHAGVA
                  shall not be liable in any manner if the Customer is unable to
                  avail the benefits under BHAGVA Pro with a Partner Merchant
                  due to the Partner Merchant’s violation of any statutory rule,
                  regulation, and license.
                </p>

                <h6>11. Liability Limitation</h6>
                <p>
                  Notwithstanding anything otherwise set out herein, BHAGVA
                  shall in no manner be liable in any way for any in-person
                  interactions with representatives or staff of the Partner
                  Merchant or for the member’s experience at the Partner
                  Merchant. BHAGVA shall in no manner be liable to the member if
                  any outlet of Partner Merchant temporarily or permanently
                  shuts down its operations. Notwithstanding anything set out
                  herein, BHAGVA’s aggregate liability for any or all claims
                  arising from or in connection with your use of BHAGVA Pro
                  shall be limited to the membership fee paid by you at the time
                  of purchasing the membership.
                </p>

                <h6>
                  12. Call Recording (For BHAGVA Pro members in India only)
                </h6>
                <p>
                  BHAGVA may contact BHAGVA Pro members via telephone, SMS or
                  other electronic messaging or by email with information about
                  your BHAGVA Pro experience or any feedback thereon. Any calls
                  that may be made by BHAGVA, by itself or through a third
                  party, to the Members or the Merchant pertaining to the
                  experience of a Customer may be recorded for internal training
                  and quality purposes by BHAGVA or any third party appointed by
                  BHAGVA.
                </p>

                <h6>13. Assignment</h6>
                <p>
                  BHAGVA may assign or transfer any of its rights or obligations
                  under these Terms and conditions to any of its affiliates or
                  any third party at any time.
                </p>

                <h6>14. Contact Us</h6>
                <p>
                  You may contact us at [Insert Contact Information] for any
                  further queries with regard to BHAGVA Pro.
                </p>

                <h6>
                  XIV. Disclaimer of Warranties, Limitation of Liability, and
                  Indemnification
                </h6>
                <h6>1. Disclaimer of Warranties</h6>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED "AS
                  IS" AND "AS AVAILABLE" AND THAT YOUR USE OF THE SERVICES SHALL
                  BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY
                  APPLICABLE LAW, BHAGVA, ITS AFFILIATES AND THEIR RESPECTIVE
                  OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, BRANCHES,
                  SUBSIDIARIES, AND LICENSORS ("BHAGVA PARTIES") DISCLAIM ALL
                  WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                  SERVICES INCLUDING MOBILE APPS AND YOUR USE OF THEM. TO THE
                  FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE BHAGVA PARTIES
                  MAKE NO WARRANTIES OR REPRESENTATIONS THAT THE SERVICES HAVE
                  BEEN AND WILL BE PROVIDED WITH DUE SKILL, CARE AND DILIGENCE
                  OR ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT
                  AND ASSUME NO RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
                  INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY
                  DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS
                  TO AND USE OF THE SERVICES, (III) ANY UNAUTHORIZED ACCESS TO
                  OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                  STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF
                  TRANSMISSION TO OR FROM THE SERVICES, (V) ANY BUGS, VIRUSES,
                  TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                  THROUGH THE SERVICES THROUGH THE ACTIONS OF ANY THIRD PARTY,
                  (VI) ANY LOSS OF YOUR DATA OR CONTENT FROM THE SERVICES AND/OR
                  (VII) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS
                  OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                  CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
                  AVAILABLE VIA THE SERVICES. ANY MATERIAL DOWNLOADED OR
                  OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT
                  YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER
                  DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY
                  SUCH MATERIAL. THE BHAGVA PARTIES WILL NOT BE A PARTY TO OR IN
                  ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                  YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU ARE
                  SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
                  INTERACTIONS WITH OTHER CUSTOMERS OF THE SERVICES AND WITH
                  OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A
                  RESULT OF YOUR USE OF THE SERVICES. NO ADVICE OR INFORMATION,
                  WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BHAGVA OR
                  THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT
                  EXPRESSLY STATED IN THE TERMS. UNLESS YOU HAVE BEEN EXPRESSLY
                  AUTHORIZED TO DO SO IN WRITING BY BHAGVA, YOU AGREE THAT IN
                  USING THE SERVICES, YOU WILL NOT USE ANY TRADE MARK, SERVICE
                  MARK, TRADE NAME, LOGO OF ANY COMPANY OR ORGANIZATION IN A WAY
                  THAT IS LIKELY OR INTENDED TO CAUSE CONFUSION ABOUT THE OWNER
                  OR AUTHORIZED USER OF SUCH MARKS, NAMES OR LOGOS.
                </p>

                <h6>2. Limitation of Liability</h6>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL THE BHAGVA PARTIES BE LIABLE TO YOU FOR ANY DAMAGES
                  RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
                  CONTENT, AND/OR (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
                  ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                  OF THE SERVICES INCLUDING MOBILE APP, AND/OR (III) ANY
                  UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND
                  ALL PERSONAL INFORMATION STORED THEREIN, AND/OR (IV) ANY
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR
                  SERVERS, AND/OR (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                  LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY
                  ANY THIRD PARTY, AND/OR (VI) ANY LOSS OF YOUR DATA OR CONTENT
                  FROM THE SERVICES, AND/OR (VII) ANY ERRORS OR OMISSIONS IN ANY
                  CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                  RESULT OF YOUR USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                  OTHERWISE MADE AVAILABLE VIA THE SERVICES, WHETHER BASED ON
                  WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND
                  WHETHER OR NOT THE BHAGVA PARTIES ARE ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES, AND/OR (VIII) THE DISCLOSURE OF
                  INFORMATION PURSUANT TO THESE TERMS OR OUR PRIVACY POLICY,
                  AND/OR (IX) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT
                  DETAILS SECURE AND CONFIDENTIAL, AND/OR (X) LOSS OR DAMAGE
                  WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO
                  LOSS OR DAMAGE AS A RESULT OF RELIANCE PLACED BY YOU ON THE
                  COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS
                  A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND
                  ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE
                  SERVICES, AND/OR DELAY OR FAILURE IN PERFORMANCE RESULTING
                  FROM CAUSES BEYOND BHAGVA'S REASONABLE CONTROL. IN NO EVENT
                  SHALL THE BHAGVA PARTIES BE LIABLE TO YOU FOR ANY INDIRECT,
                  INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL
                  DAMAGES WHATSOEVER, HOWEVER CAUSED AND UNDER ANY THEORY OF
                  LIABILITY, INCLUDING BUT NOT LIMITED TO, ANY LOSS OF PROFIT
                  (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF
                  GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED,
                  COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER
                  INTANGIBLE LOSS.
                </p>

                <h6>3. Indemnification</h6>
                <p>
                  You agree to indemnify, defend, and hold harmless the BHAGVA
                  Parties from and against any third party claims, damages
                  (actual and/or consequential), actions, proceedings, demands,
                  losses, liabilities, costs and expenses (including reasonable
                  legal fees) suffered or reasonably incurred by us arising as a
                  result of, or in connection with: (i) Your Content, (ii) your
                  unauthorized use of the Services, or products or services
                  included or advertised in the Services; (iii) your access to
                  and use of the Services; (iv) your violation of any rights of
                  another party; or (v) your breach of these Terms, including,
                  but not limited to, any infringement by you of the copyright
                  or intellectual property rights of any third party. We retain
                  the exclusive right to settle, compromise and pay, without
                  your prior consent, any and all claims or causes of action
                  which are brought against us. We reserve the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us and you
                  agree to cooperate with our defense of these claims. You agree
                  not to settle any matter in which we are named as a defendant
                  and/or for which you have indemnity obligations without our
                  prior written consent. We will use reasonable efforts to
                  notify you of any such claim, action or proceeding upon
                  becoming aware of it.
                </p>
              </div>
            </section>
            <section>
              XIV. Termination of your access to the services 1. You can delete
              your account at any time by contacting us via the "Contact Us"
              link at the bottom of every page or by following this process: Go
              to Profile &gt; Setting &gt; Security &gt; click on the 'Delete
              Account' button and ceasing further use of the Services. 2. We may
              terminate your use of the Services and deny you access to the
              Services in our sole discretion for any reason or no reason,
              including your: (i) violation of these Terms; or (ii) lack of use
              of the Services. You agree that any termination of your access to
              the Services may be affected without prior notice, and acknowledge
              and agree that we may immediately deactivate or delete your
              account and all related information and/or bar any further access
              to your account or the Services. If you use the Services in
              violation of these Terms, we may, in our sole discretion, retain
              all data collected from your use of the Services. Further, you
              agree that we shall not be liable to you or any third party for
              the discontinuation or termination of your access to the Services
              XVI. General terms 1. Interpretation: The section and subject
              headings in these Terms are included for reference only and shall
              not be used to interpret any provisions of these Terms. 2. Entire
              Agreement and Waiver: The Terms, together with the 'Privacy
              Policy' and 'Guidelines and Policies', shall constitute the entire
              agreement between you and us concerning the Services. No failure
              or delay by us in exercising any right, power or privilege under
              the Terms shall operate as a waiver of such right or acceptance of
              any variation of the Terms and nor shall any single or partial
              exercise by either party of any right, power or privilege preclude
              any further exercise of that right or the exercise of any other
              right, power or privilege. 3. Severability: If any provision of
              these Terms is deemed unlawful, invalid, or unenforceable by a
              judicial court for any reason, then that provision shall be deemed
              severed from these Terms, and the remainder of the Terms shall
              continue in full force and effect. 4. Partnership or Agency: None
              of the provisions of these Terms shall be deemed to constitute a
              partnership or agency between you and BHAGVA and you shall have no
              authority to bind BHAGVA in any form or manner, whatsoever. 5.
              Governing Law/Waiver: (a) For Customers residing in India: These
              Terms shall be governed by the laws of India. The Courts of New
              Delhi shall have exclusive jurisdiction over any dispute arising
              under these terms. (b) For all Customers: YOU MUST COMMENCE ANY
              LEGAL ACTION AGAINST US WITHIN ONE (1) YEAR AFTER THE ALLEGED HARM
              INITIALLY OCCURS. FAILURE TO COMMENCE THE ACTION WITHIN THAT
              PERIOD SHALL FOREVER BAR ANY CLAIMS OR CAUSES OF ACTION REGARDING
              THE SAME FACTS OR OCCURRENCE, NOTWITHSTANDING ANY STATUTE OF
              LIMITATIONS OR OTHER LAW TO THE CONTRARY. WITHIN THIS PERIOD, ANY
              FAILURE BY US TO ENFORCE OR EXERCISE ANY PROVISION OF THESE TERMS
              OR ANY RELATED RIGHT SHALL NOT CONSTITUTE A WAIVER OF THAT RIGHT
              OR PROVISION. 6. Carrier Rates may Apply: By accessing the
              Services through a mobile or other device, you may be subject to
              charges by your Internet or mobile service provider, so check with
              them first if you are not sure, as you will be solely responsible
              for any such costs incurred. 7. Linking and Framing: You may not
              frame the Services. You may link to the Services, provided that
              you acknowledge and agree that you will not link the Services to
              any website containing any inappropriate, profane, defamatory,
              infringing, obscene, indecent, or unlawful topic, name, material,
              or information or that violates any intellectual property,
              proprietary, privacy, or publicity rights. Any violation of this
              provision may, in our sole discretion, result in termination of
              your use of and access to the Services effective immediately.
              XVII. Notice of copyright infringement BHAGVA shall not be liable
              for any infringement of copyright arising out of materials posted
              on or transmitted through the BHAGVA Platform, or items advertised
              on the BHAGVA Platform, by end users or any other third parties.
              We respect the intellectual property rights of others and require
              those that use the Services to do the same. We may, in appropriate
              circumstances and at our discretion, remove or disable access to
              material on the Services that infringes upon the copyright rights
              of others. We also may, in our discretion, remove or disable links
              or references to an online location that contains infringing
              material or infringing activity. In the event that any Customers
              of the Services repeatedly infringe on others' copyrights, we may
              in our sole discretion terminate those individuals' rights to use
              the Services If you believe that your copyright has been or is
              being infringed upon by material found in the Services, you are
              required to follow the below procedure to file a notification: i.
              Identify in writing the copyrighted material that you claim has
              been infringed upon; ii. Identify in writing the material on the
              Services that you allege is infringing upon copyrighted material,
              and provide sufficient information that reasonably identifies the
              location of the alleged infringing material (for example, the user
              name of the alleged infringer and the business listing it is
              posted under); iii. Include the following statement: "I have a
              good faith belief that the use of the content on the Services as
              described above is not authorized by the copyright owner, its
              agent, or law"; iv. Include the following statement: "I swear
              under penalty of perjury that the information in my notice is
              accurate and I am the copyright owner or I am authorized to act on
              the copyright owner's behalf"; v. Provide your contact information
              including your address, telephone number, and e-mail address (if
              available); vi. Provide your physical or electronic signature;
              vii. Send us a written communication to …….{" "}
              <a href="legal@bhagva.in">legal@bhagva.in</a> You may be subject
              to liability if you knowingly make any misrepresentations on a
              take-down notice.
            </section>
            <section>
              <h6>XVI. Contact Us:</h6>

              <h6>1. Details of the Company</h6>
              <ul>
                <li>
                  <strong>Legal Entity Name:</strong> BHAGVA-ONTECH PRIVATE
                  LIMITED
                </li>
                <li>
                  <strong>CIN:</strong> U80301HR2022PTC103696
                </li>
                <li>
                  <strong>Registered Address:</strong> Gali No. 7, Opposite
                  Sector -10 Bus Depot, Plot no. - 464, Gurugram, Haryana.
                </li>
                <li>
                  <strong>Details of website and Application:</strong>{" "}
                  <a
                    href="http://www.bhagva.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.bhagva.in
                  </a>{" "}
                  (Website) and “BHAGVA” application for mobile and handheld
                  devices
                </li>
                <li>
                  <strong>Contact Details:</strong>{" "}
                  <a href="mailto:info@bhagva.in">info@bhagva.in</a>
                </li>
              </ul>

              <h6>2. Grievance Redressal Mechanism:</h6>

              <h6>i. Customer Care Channels</h6>
              <ul>
                <li>
                  For any order related issue, you may first reach out to us via
                  chat support on the BHAGVA app for real-time resolution.
                </li>
                <li>
                  You may write to us at{" "}
                  <a href="mailto:order@bhagva.in">order@bhagva.in</a> and we
                  will strive to resolve your order-related grievance within the
                  timelines prescribed under applicable laws.
                </li>
                <li>
                  For a complaint raised on{" "}
                  <a href="mailto:order@bhagva.in">order@bhagva.in</a>, you will
                  receive a unique ticket number to track the status of your
                  complaint.
                </li>
                <li>
                  If you do not receive a satisfactory response from the chat
                  support and{" "}
                  <a href="mailto:order@bhagva.in">order@bhagva.in</a>, you can
                  escalate the matter to our Grievance Officer by referencing
                  the ticket number generated from{" "}
                  <a href="mailto:order@bhagva.in">order@bhagva.in</a>. We shall
                  address your concern within an estimated time of forty-eight
                  (48) hours.
                </li>
              </ul>

              <h6>ii. Details of the Grievance Officer</h6>
              <ul>
                <li>
                  <strong>Name:</strong> ...................................
                </li>
                <li>
                  <strong>Address:</strong> ...................................
                </li>
                <li>
                  <strong>Email address:</strong>{" "}
                  <a href="mailto:grievance@bhagva.in">grievance@bhagva.in</a>
                </li>
                <li>
                  <strong>Time:</strong> Monday - Friday (09:00 a.m. to 06:00
                  p.m.)
                </li>
              </ul>

              <p>
                For Customers residing in India, please note, in compliance with
                The Information Technology Act, 2000 and the rules made
                thereunder, as well as The Consumer Protection Act 2019, and the
                rules made thereunder, the grievance redressal mechanism,
                including the contact details of the Grievance Officer and Nodal
                Officer, are given herein above.
              </p>

              <p>
                Please note: BHAGVA does not solicit confidential information
                such as OTP/CVV/PIN NUMBER/Card number either through call or
                mail or any other means. Please do not reveal these details to
                fraudsters and imposters claiming to be calling on BHAGVA’s
                behalf. You may report such suspicious activities to{" "}
                <a href="mailto:order@bhagva.in">order@bhagva.in</a>.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandConditions;
