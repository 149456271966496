import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div>
      <section class="bg-light py-3 py-md-5 mb-5">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 class="mb-4 display-5 text-center">Contact Us</h2>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row justify-content-lg-center">
            <div class="col-12 col-lg-9">
              <div class="bg-white border rounded shadow-sm overflow-hidden p-5">
                <Link to="/">Back</Link>
                <h3 className="text-center">BHAGVA ONTECH PRIVATE LIMITED</h3>
                <div className="text-center mt-3 mt-5">
                  <h4>Registered Address</h4>
                  <p>
                    Gali No. 7, Opposite Sector -10 ,<br />
                    Bus Depot, Plot no. - 464,
                    <br />
                    Gurugram Gurgaon <br />- 122001 Haryana - India
                  </p>
                  <p>
                    <strong>Mobile Number:</strong> +91 73575 57008
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
