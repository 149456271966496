import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import TermsandConditions from "./TermsandConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import CancellationAndRefund from "./CancellationAndRefund";
import ContactUs from "./ContactUs";
import ShippingAndDelivery from "./assets/ShippingAndDelivery";

const main = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/terms-and-conditions" element={<TermsandConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
        <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
        <Route path="/contact-us" element={<ContactUs  />} />
        <Route path="/shipping-and-delivery" element={<ShippingAndDelivery />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default main;
