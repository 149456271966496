import React from "react";
import { Link } from "react-router-dom";

const ShippingAndDelivery = () => {
  return (
    <div className="container p-5">
      <Link to="/">Back</Link>

      <h3> Shipping & Delivery Information </h3>
      <p>Hassle-Free Shipping, Secured Payments </p>
      <p>
        At Ecormaers, we prioritize getting your orders to you safely and on
        time. Here’s everything you need to know about our shipping and delivery
        process:
      </p>

      <h2>Shipping Methods</h2>
      <p>Standard Shipping: Delivered within 5-7 business days.</p>

      <p>Note: Shipping times may vary based on your location.</p>

      <h2>Order Tracking </h2>
      <p>
        Once your order is dispatched, you'll receive an email with a tracking
        link. You can monitor your package every step of the way until it
        reaches your doorstep.
      </p>
      <p>Secure Payments with Razorpay</p>
      <p>
        We’ve partnered with Razorpay to ensure your transactions are 100%
        secure. Whether you’re paying by credit card, debit card, or UPI, rest
        assured that your payment details are protected by industry-leading
        security protocols.
      </p>
      <p>Pay Now with Razorpay</p>

      <h2>Shipping Charges</h2>
      <p>
        Standard Shipping: ₹2200 (Free for orders over ₹500) Any applicable
        shipping charges will be calculated at checkout.
      </p>

      <h2>Delivery Locations </h2>
      <p>
        We deliver across India. Unfortunately, we do not ship to P.O. boxes or
        military addresses at this time.
      </p>
    </div>
  );
};

export default ShippingAndDelivery;
