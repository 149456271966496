import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container p-5">
         <Link to="/">Back</Link>
      <h3 className="text-center mb-3">Privacy Policy</h3>
      This privacy policy is valid for all Bhagva's websites (in any languages
      available). This document explains how the collection of personally
      identifiable information is processed during the use of our website.
      Personally, identifiable information means all personal data, such as your
      name, physical address, e-mail address or other behavioural data as a user
      of the website. The protection of your personally identifiable information
      is very important to Bhagva. If you have any questions or would like
      additional information on Bhagva's data protection, please contact us at
      info@Bhagva.in. Our privacy policy is subject to updates due to the
      constant technological development, as well as any changes in our services
      or the legal situation that governs our business, among other reasons.
      Therefore, we ask that you review this document regularly to keep up to
      date on the changes.
      <h3>1. General Information About Privacy And Purpose Of Storage</h3>
      We treat user privacy as paramount and maintain the following policies to
      ensure your privacy is preserved. We collect data so that we can
      continually improve the deals and services that we offer you.
      <h3>2. Data Protection Contact Point</h3>
      To contact our data protection officer, send an e-mail toinfo@bhagva.in
      <h3>3. Your Rights </h3>
      Listed below are the rights attached to the personally identifiable
      information concerning you: 3.1. General right <br />
      3.2. Rights in the processing of data based on legitimate interests In
      accordance, you have the right, at any moment and for reasons related to
      your individual situation, to oppose the processing of personally
      identifiable information relating to you - processing of data on grounds
      of public interest or data processing for the protection of a legitimate
      interest. The same applies to profiling based on this provision. In the
      event of objection on your part, we will stop processing your personally
      identifiable information, unless we present solid and legitimate grounds
      for processing that prevail over your interests, rights and freedoms, or
      if the processing of information contributes to defending, exercising or
      asserting a legal claim.
      <br />
      3.3. Rights under direct advertising If we process your personally
      identifiable information for the purposes of direct advertising, you have
      the right to oppose its processing for that purpose at any time. The same
      applies to profiling, where appropriate, insofar as it is associated with
      such direct advertising. In case of objection on your part, we will stop
      processing your personally identifiable information for these purposes.
      <br />
      3.4. Right to complain to a supervisory authority You have the right to
      complain to a supervisory authority regarding the protection of data about
      the processing performed by Bhagva of your personally identifiable
      information.
      <br />
      <h3>
        4. Collection Of Personally Identifiable Information When Visiting Our
        Website
      </h3>
      If you only use our website for informational purposes, that is, if you do
      not register or provide us with any information, we will only collect the
      personal identification information that your browser transmits to our
      server. We will only collect the data technically necessary to properly
      display our site and ensure its stability and security. We will only use
      this information in case of suspected misuse, in order to facilitate the
      identification of the responsible person as detailed below: - IP address,
      date and time of the query, in relation to the Greenwich Mean Time (GMT),
      the content of the request, access status / HTTP status code, the volume
      of data transferred in each case, website receiving the request, browser,
      operating system and respective interface, language and version of the
      browser.
      <h3>5. Contact By Email Or Contact Form (Customer Service)</h3>
      By contacting us via email or through a contact form, we will store the
      data provided by you (email address, possibly your name and phone number)
      so that we can address your questions. The provision of this information
      is made expressly, voluntarily and with your consent. If the data provided
      includes information regarding communication channels (such as your email
      address or telephone number), we would like to point out that, where
      appropriate, we may contact you through these communication channels to
      respond to your request. You may revoke this consent at any time with
      effect for the future. We will delete the data that appears in this
      context when its storage is no longer necessary. If there are legal
      retention requirements, we will limit the processing of this type of data.
      <h3>6. Newsletter</h3>
      6.1. General information <br />
      With your consent, you may subscribe to our newsletter, which will provide
      you with information about our current offers. The only requirement for
      sending the newsletter is your email address. Upon your confirmation, we
      will store your email address for the purpose of sending you the
      newsletter. Your consent to the sending of the newsletter may be revoked
      at any time and this will cause the subscription to be cancelled. To
      cancel, simply click on the link provided in each newsletter email or
      contact the above-mentioned data protection email.
      <br />
      6.2. Newsletter tracking We evaluate your behaviour as a user when we send
      the newsletter. For this evaluation, outgoing emails include tracking
      pixels stored on our website. In order to carry out the evaluations, we
      cross-reference the mentioned data and the tracking pixels with your email
      address and an individual identifier. xcWe use this data to adapt the
      newsletter to your interests. By doing so, we get the information of when
      you read them and clicked. We cross this data with actions you have taken
      on our website.
      <br />
      <h3>7. Personal Account </h3>
      The user can subscribe to the site Bhagva and create an account. We
      collect and save the following data in the registration:
      <br />
      - Name <br />
      - Email <br />
      - Password <br />
      It is mandatory to provide the above data. You may voluntarily provide
      further information in the logged in area. Registration is voluntary, but
      may be mandatory to use some of our services. If you decide to create
      reviews and descriptions, you may choose personal identification
      information that Bhagva. and platform visitors can see. You can change
      these settings at any time. Bhagva does not publish personally
      identifiable information without receiving express consent from you to
      that effect. In case of cancellation of the account, we will continue to
      store the data that you provided voluntarily during the time you used the
      portal for statistical purposes. However, you may request that we delete
      the data if you wish.
      <h3>8. Registration And Login Tools</h3>
      8.1. Facebook Connect <br />
      You can subscribe and log in to Bhagva using your Facebook account. If you
      do so through Facebook, it will ask for your permission to provide us with
      certain data that appears on your Facebook account. This may include your
      first name, last name and email address, gender, location, time zone, date
      of birth, profile photo, information about your preferences, and your
      friends list. Facebook will collect this data and transmit it to Bhagva in
      accordance with Facebook's privacy policy. You can control the information
      we receive from Facebook using the privacy settings of your Facebook
      account. If you subscribe to the platform through Facebook, your Bhagva
      account will automatically be linked to your Facebook account and
      information about your activities on our website, if applicable, may be
      shared on Facebook and published in your chronology and feed of news.
      <br />
      8.2. Google+ / Gmail You can also sign up and log in with your Google + /
      Gmail account. Google will request your permission to provide us with
      certain details of your account, such as: your first name, last name,
      email address, gender, your profile photo, and friends list. Google will
      collect this data and transmit it to Bhagva in accordance with Google's
      privacy policy. People in your Google+ circles will be able to see
      information related to the activities you performed on our website. In
      addition, this information will be shared by Google in accordance with
      their respective terms and conditions of use and privacy policy. For more
      details about managing the activities that are shared in your Google+
      account, see the Google help page. On the Google+ app settings page, you
      can determine which people in your Google+ circles can view your
      activities on our website. This data will be used to create and customise
      your account.
      <br />
      8.3. LinkedIn You can subscribe to and log in to Bhagva using your
      LinkedIn account. This will request your permission to provide us with
      certain data that appears on your LinkedIn account. LinkedIn will collect
      this data and transmit it to Bhagva in accordance with LinkedIn's privacy
      policy. You can control the information we receive from LinkedIn using the
      privacy settings of your LinkedIn account.
      <h3>9. Use Of Cookies </h3>
      During the use of our website, cookies will be stored on your device.
      Cookies are small text files that are stored on your hard drive assigned
      to the browser you use. Cookies can not run programs or infect your device
      with viruses. They serve to make the website globally more intuitive and
      efficient. We also use cookies to recognize your device for future visits.
      This website uses the following types of cookies, whose scope and function
      are explained below:
      <br />
      9.1. Temporary Cookies These cookies are automatically deleted when you
      close your browser. In particular, this category includes session cookies,
      which store a session identifier through which you can assign different
      requests from your browser to the joint session. Such a cookie will allow
      your device to be recognized again when you return to our website. Session
      cookies are deleted when you log off or close the browser.
      <br />
      9.2. Persistent Cookies These cookies are automatically deleted after a
      set amount of time that varies depending on the cookie. You may delete
      cookies in your browser's security settings at any time.
      <br />
      9.3. Prevent cookies You can configure your browser according to your
      preferences and, for example, reject third party cookies or any kind of
      cookies. But be aware that in this case it may not be possible to use all
      the functions of the website. 9.4. Legal basis and storage period The
      legal bases for the possible processing of personally identifiable
      information and their storage period vary and are described in the
      following sections.
      <br />
      <h3>10. Analysis And Optimization</h3>
      For the purpose of analysing and optimising our website, we use different
      services described in this section. These services allow us to analyse,
      for example, how many users access our site, which information is most
      sought after. The data we collect includes the websites from which a
      particular user comes to our website (referral), which subpages on the
      website are visited and how often, as well as the length of time that
      users remain on those pages. This information helps us improve the
      experience on our website and to make it more intuitive. The data
      collected is not intended to personally identify individual users.
      <br />
      10.1. Google Analytics This website uses Google Analytics, a web analytics
      service provided by Google Inc. (1600 Amphitheatre Parkway Mountain View,
      CA 94043, USA). Google Analytics uses cookies that allow your use of the
      website to be reviewed. Google will use this information on behalf of the
      operator of this website to evaluate your use of it for the purpose of
      compiling reports on the activity of the website and providing other
      services related to the use of the website and the internet to the
      respective operator. The data we send and associated with cookies, user
      information (for example, the user's IP address) and promotional
      identifiers are deleted after 12 months from the last use of our services.
      Data whose expiration period has arrived are automatically deleted. For
      more information about terms and conditions of use and data protection,
      please see https://policies.google.com/?hl=en.
      <br />
      10.2. Google Tag Manager For transparency reasons, we inform you that we
      use Google Tag Manager. This system does not collect any personally
      identifiable information. Tag manager allows us to incorporate and manage
      our tags more easily. Tags are snippets of code that measure user traffic
      and behaviour, record the effects of online advertising and social
      channels for the purpose of remarketing operations focused on a particular
      audience. Help test and optimise the site, among other actions. For more
      information about Google Tag Manager,
      see:https://www.google.com/analytics/tag-manager/use-policy/
      <br />
      <h3>11. Advertising</h3>
      We use cookies and pixels for marketing purposes in order to approach our
      users with advertising best suited to their interests. We also use cookies
      to evaluate the effectiveness of our advertising efforts. This information
      may also be shared with third parties, such as ad networks, and serve the
      legitimate direct marketing interest. At any time, you may object to the
      processing of your data for purposes related to this type of advertising.
      This may prevent the storage of cookies through your browser settings.
      <br />
      11.1. Advertising on Google To make ads to advertise our services, we use
      tools provided by Google (such as Google Adwords). In this context, we use
      conversion tracking to present personalised online ads based on the
      interests and behaviour of users on our website. Ads are served based on
      searches performed on Google's ad network websites. We were able to
      combine our ads with certain search terms. By using cookies, we were able
      to display ads based on your previous visits to our website. When you
      click on an ad, Google places a cookie on the ad's device. For more
      information about the cookie technology used, please see Google's
      statements regarding website statistics, as well as its data privacy
      policy. Using this technology, we and Google as customers of the same, we
      received information that a user clicked on the ad and was redirected to
      our websites. Information acquired in this way is only used for
      statistical analysis related to ad optimization. We have not received any
      information that allows us to personally identify a visitor. If you do not
      wish to be part of these statistics, you may prevent the storage of
      cookies that this technology uses when adjusting the settings in your
      browser. In that case, your visit will not be incorporated into user
      statistics. In addition, you can opt out of interest-based ads on Google
      through ad settings. Alternatively, you can disable the use of cookies by
      third parties through the Network Advertising Initiative deactivation
      tool. However, we and Google will continue to receive statistical
      information about how many users visit this site and when.
      <br />
      11.2. Advertising on Facebook and Instagram Facebook and Instagram Ads
      (from Facebook Inc., 1601 S. California Avenue, Palo Alto, CA 94304) is
      also used as part of the online advertising strategy of our website. Your
      use behaviour, which does not allow your personal identification, may be
      transmitted to Facebook for analysis and marketing purposes. In this
      process, a Facebook pixel is set up, which will allow us to collect
      information about your activities on the website (such as browsing
      behaviour, visited pages, etc.). Your IP address is stored and used for
      geographic modulation. For more information about the purpose and extent
      of data collection and further processing and use as well as privacy
      settings, see Facebook's privacy policy.
      <br />
      <h3>12. Data Transfer</h3>
      As a general rule, your data will not be transmitted to third parties
      unless there is a legal obligation to do so or if you have given your
      express consent to the transfer. When you are directed to a third-party
      site from Bhagva, also note the data privacy policy of the respective
      service provider. They are responsible for the content of the services
      they offer, although we evaluate their services in terms of compliance
      with legal requirements to a reasonable extent.
      <h3>13. Data Security </h3>
      We take reasonable technical and operational safety precautions to protect
      your data from tampering, loss, destruction or unauthorised access by
      unauthorised persons, whether intentional or accidentally. Our security
      measures are regularly reviewed and updated in line with technological
      advances.
      <h3>14. Online Privacy Policy Only </h3>
      This online privacy policy applies only to information collected through
      our website and not to information collected offline.
      <h3>15. Your Consent</h3>
      By using our site, you consent to our online privacy policy
    </div>
  );
};

export default PrivacyPolicy;
