import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import FaceBbook from "./assets/Face-book.png";
import instagram from "./assets/Insta.png";
import Bhagva_1 from "./assets/English LP.jpg";
import Bhagva_2 from "./assets/Hindi LP.jpg";
import Bhagva_3 from "./assets/Telugu LP.jpg";
import Bhagva_4 from "./assets/Tamil LP.jpg";
import Bhagva_5 from "./assets/Bengali  LP.jpg";

function App() {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div>
      <div className="container-fluid header-part">
        <div className="wrapper">
          <div className="social-icons">
            <Link
              to="https://www.facebook.com/profile.php?id=61564919241616"
              target="_blank"
            >
              <img src={FaceBbook} alt="Face-book.png" />
            </Link>
            <Link
              to="https://www.instagram.com/bhagva_official_/"
              target="_blank"
            >
              <img src={instagram} alt="Insta.png" />
            </Link>
          </div>

          <div className="image-container">
            <Slider {...sliderSettings}>
              <div>
                <img src={Bhagva_1} alt="Bhagva Image" />
              </div>

              <div>
                <img src={Bhagva_2} alt="Bhagva Image" />
              </div>

              <div>
                <img src={Bhagva_3} alt="Bhagva Image" />
              </div>

              <div>
                <img src={Bhagva_4} alt="Bhagva Image" />
              </div>

              <div>
                <img src={Bhagva_5} alt="Bhagva Image" />
              </div>
            </Slider>

            <div className="bottom-container">
              <Link to="https://shop.bhagva.life" target="_blank">
                <button className="book-button">Book Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="single_footer">
                <ul className="footer-links">
                  <li>
                    <Link to="terms-and-conditions">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="cancellation-and-refund">
                      Cancellation And Refund
                    </Link>
                  </li>
                  <li>
                    <Link to="shipping-and-delivery">Shipping And Delivery</Link>
                  </li>

                  <li>
                    <Link to="contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <p className="copyright">@2024 inc. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
