import React from "react";
import { Link } from "react-router-dom";


const CancellationAndRefund = () => {
  return (
    <div>
      <div className="container p-5">
      <Link to="/">Back</Link>
        <h4 className="title">RETURN, REFUND AND CANCELLATION POLICIES</h4>
        <div className="return-section">
          <div>
            <h6>RETURN/REFUND POLICIES:</h6>
            <ol type="number">
              <li>
                <p>
                  No returns will be offered on Items that have been delivered
                  without any damages or defects.
                </p>
              </li>
              <li>
                <p>
                  If the product is wrongfully delivered (different SKU instead
                  of the SKU mentioned in the order confirmation) or has a
                  genuine quality/manufacturing defect, then we are open to
                  extending full refund or re-fulfilling your order against the
                  return of the product subject to the fact of such defect being
                  established.{" "}
                </p>
              </li>
              <li>
                <p>
                  To report damage in transit please get in touch with our
                  customer care within 48 hours of delivery (Customer Care
                  Number/email will be provided)
                </p>
              </li>
              <li>
                <p>
                  Our customer care team will look into the issue and revert
                  back within 48 working hours.
                </p>
              </li>
              <li>
                <p>
                  Refunds will be made (i) within 14 working days from the date
                  of receipt of returned goods if the order is already shipped
                  (ii) within 14 working days from the date of cancellation of
                  the order if the order is not yet shipped.
                </p>
              </li>
              <li>
                <p>
                  No refunds would be given if the customer has provided wrong
                  or incomplete shipping address, there are X failed delivery
                  attempts by our shipping agency and/or the package is refused
                  by the recipient.
                </p>
              </li>
              <li>
                <p>
                  Notwithstanding anything contained herein, the following
                  products shall not be eligible for return or replacement, viz:
                  Any product that is returned without all original packaging
                  and accessories, including the retail box and all other items
                  originally included with the product at the time of delivery;
                </p>
              </li>
              <li>
                <p>
                  All costs of return shipping need to be borne by the purchaser
                  (Unless the item received is defective)
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h6>RETURNING A GIFT:</h6>
            <ol type="number">
              <li>
                <p>
                  1. We understand if the gift does not work for you. Please
                  email us at{" "}
                  <a href="mailto:sales@bhagva.in">sales@bhagva.in</a> for
                  assistance in returning the item.
                </p>
              </li>
              <li>
                <p>
                  All the above conditions regarding return of goods apply to
                  the return of gifts as well.
                </p>
              </li>
              <li>
                <p>
                  You cannot obtain store credit for a returned gift item;
                  however, we can definitely process a gift exchange for you for
                  another item.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h6>CANCELLATION POLICIES:</h6>
            <ol type="number">
              <li>
                <p>
                  If you have to cancel an order, please do so within 24 hours
                  of placing the order. It is NOT possible to cancel part of
                  your order. We recommend you cancel the entire order and
                  re-purchase required quantity to simplify the transaction.
                </p>
              </li>
              <li>
                <p>
                  If you cancel your order before your product has shipped, we
                  will refund the entire amount subject to deduction of charges
                  if any and whereas you cancel your order after the shipment
                  but before receipt of the item, the same will be liable for
                  deduction of the two-way shipping costs.
                </p>
              </li>
              <li>
                <p>
                  All cancellations are subject to a cancellation charge/bank
                  transaction charge of 2.5% of your total order value.{" "}
                </p>
              </li>
              <li>
                <p>
                  There are circumstances when the company might have to cancel
                  a particular order.
                </p>
              </li>
              <li>
                <p>
                  5. Your receipt of an electronic or another form of order
                  confirmation does not signify our final acceptance of your
                  order and <a href="www.bhagva.in">www.bhagva.in</a> reserves
                  the right at any time after the receipt of your order to
                  accept or decline your order for any reason.
                </p>
              </li>
              <li>
                <p>
                  Although our online stock inventory is programmed to update
                  automatically, www.themaevastore.com reserves the right at any
                  time after receipt of your order, to inform you via telephone
                  or email or mobile text about a shortfall in the quantity you
                  ordered of any item. In all such cases, we have the right to
                  either supply you less than the quantity you ordered or cancel
                  your order with prior notice to you.
                </p>
              </li>
              <li>
                <p>
                  If an order is to be calculated after shipment, the individual
                  return policy of the vendor applies. A return fee is charged
                  by vendors in most cases.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationAndRefund;
